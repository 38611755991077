import React, { useEffect, useState } from "react";
import DailyClassLinkTable from "../../components/classLinks/DailyClassLinkTable";
import Pagination from "../../components/Pagination";
import { Link } from "react-router-dom";
import axiosInstance from "../../utils/Axios";
import { toast } from "react-toastify";
import {
  GetLink,
  classNa,
  dateSelect,
  division,
  year,
} from "../../utils/EndPoints";
import { FaPlus } from "react-icons/fa6";
import StudentLoader from "../../components/loading/StudentLoader";

const VIewDailyClass = () => {
  const [data, setData] = useState({});
  const [classData, setClassData] = useState([]);
  const [yearData, setYearData] = useState([]);
  const [divisionData, setDivisionData] = useState([]);
  const [dateData, setDateData] = useState([]);
  const [page, setPage] = useState(1);
  const [entries, setEntries] = useState(100);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    batch_year: "",
    class_name: "",
    division: "",
    date: "",
  });

  const getAllData = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(
        `${GetLink}?page=${page}&count=${entries}`
      );
      setData(response?.data);
    } catch (error) {
      console.log(error);
    }finally{
      setLoading(false);
    };
  };

  const inputChangeHandler = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axiosInstance.get(
        `${GetLink}?batch_year=${formData.batch_year}&class_name=${formData.class_name}&division=${formData.division}&date=${formData?.date}`
      );
      setData(response?.data);
    } catch (error) {
      console.log(error);
      toast.error("Something Went Wrong");
    }finally{
      setLoading(false);
    };
  };

  useEffect(() => {
    getAllData();
    window.scrollTo(0, 0);

    axiosInstance
      .get(year)
      .then((res) => {
        setYearData(res?.data?.batch_year);
      })
      .catch((error) => {
        console.log(error);
      });
    axiosInstance
      .get(classNa)
      .then((res) => {
        setClassData(res?.data?.class_name);
      })
      .catch((error) => {
        console.log(error);
      });
    axiosInstance
      .get(division)
      .then((res) => {
        setDivisionData(res?.data?.division);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    if (formData.batch_year && formData.class_name && formData.division) {
      axiosInstance
        .get(
          `${dateSelect}?batch_year=${formData.batch_year}&class_name=${formData.class_name}&division=${formData.division}`
        )
        .then((res) => {
          setDateData(res?.data?.distinct_dates);
        })
        .catch((error) => {
          toast.warning(error?.response?.data?.message);
          console.log(error);
        });
    }
  }, [formData.batch_year, formData.class_name, formData.division]);

  return (
    <div>
      {/* add class link button */}
      <div className="flex flex-col md:flex-row items-center md:justify-between gap-3">
        <h1 className="font-mono font-bold text-2xl text-[#F4881F]">
          Class Links{" "}
        </h1>
        <div className="">
          <h1 className="font-semibold text-lg font-mono text-[#F4881F] uppercase">
            {data?.class_name} - <span>{data?.division}</span> -{" "}
            <span>{data?.batch_year}</span>
          </h1>
        </div>
        <div className="flex items-center gap-3">
          <Link
            to={"/dashboard/daily-class-update"}
            className="bg-[#F4881F] p-2 rounded text-white shadow-lg hover:scale-105 ease-in-out duration-300"
          >
            <div className="flex items-center gap-2">
              <FaPlus />
              Add New Class Link
            </div>
          </Link>
        </div>
      </div>

      {/* Filter Part */}
      <div className="flex flex-col gap-5 mt-8">
        <form
          onSubmit={submitHandler}
          action=""
          className="w-full gap-5 flex flex-col md:flex-row"
        >
          <div className="w-full flex flex-col items-start">
            <label htmlFor="" className="mb-2 font-medium">
              Select Class
            </label>
            <select
              name="class_name"
              onChange={inputChangeHandler}
              id=""
              className="rounded text-sm w-full border border-[#F4881F]/30 p-2 focus:outline-none"
            >
              <option value="">Choose A Class</option>
              {classData.map((items, i) => (
                <option key={i} value={items}>
                  {items}
                </option>
              ))}
            </select>
          </div>

          <div className=" w-full flex flex-col items-start">
            <label htmlFor="" className="mb-2 font-medium">
              Select Division
            </label>
            <select
              name="division"
              onChange={inputChangeHandler}
              id=""
              className="rounded text-sm w-full border border-[#F4881F]/30 p-2 focus:outline-none"
            >
              <option value="">Choose A Division</option>
              {divisionData.map((items, i) => (
                <option key={i} value={items}>
                  {items}
                </option>
              ))}
            </select>
          </div>

          <div className="w-full flex flex-col items-start">
            <label htmlFor="" className="mb-2 font-medium">
              Select A Year
            </label>
            <select
              name="batch_year"
              onChange={inputChangeHandler}
              id=""
              className="rounded text-sm w-full border border-[#F4881F]/30 p-2 focus:outline-none"
            >
              <option value="">Choose A Year</option>
              {yearData.map((items, i) => (
                <option key={i} value={items}>
                  {items}
                </option>
              ))}
            </select>
          </div>

          <div className="flex justify-end">
            <button
              type="submit"
              className="bg-[#F4881F] p-2 mt-7 font-semibold text-sm px-5 rounded text-white hover:scale-105 ease-in-out duration-300"
            >
              Filter
            </button>
          </div>
        </form>
      </div>

      {/* Table */}
      <div className="mt-5 h-screen ">
        <DailyClassLinkTable
          data={data?.class_links}
          page={page}
          entries={entries}
          cb={getAllData}
        />
        <div className="flex justify-end mt-5">
          <Pagination
            Data={data?.class_links}
            page={page}
            setPage={setPage}
            getMethod={getAllData}
            entries={entries}
          />
        </div>
      </div>

      {loading && <StudentLoader />}
    </div>
  );
};

export default VIewDailyClass;
