import React from "react";

const Attandancetable = ({ data, page, entries }) => {
  return (
    <div className="w-full flex flex-col md:flex-row gap-5 rounded-lg text-sm">
      <table className="w-full bg-white shadow-lg rounded-lg">
        <thead>
          <tr className="bg-[#F4881F] text-white">
            <th className="py-2 px-4 border-b border-r">No.</th>
            <th className="py-2 px-4 border-b border-r">AD No.</th>
            <th className="py-2 px-4 border-b border-r">Name</th>
            <th className="py-2 px-4 border-b border-r">Status</th>
          </tr>
        </thead>

        <tbody>
          {data?.map((items, i) => (
            <tr key={i}>
              <td className="py-2 px-4 border-b border-r">
                {(page - 1) * entries + i + 1}
              </td>
              <td className="py-2 px-4 border-b border-r">
                {items?.admission_no}
              </td>
              <td className="py-2 px-4 border-b border-r">{items?.name}</td>
              <td
                className={`py-2 px-4 border-b border-r ${
                  items?.status === "P" ? "text-[#32c540]" : "text-[#FF474C]"
                }`}
              >
                {items?.status === "P"
                  ? "Present"
                  : items?.status === "A"
                  ? "Absent"
                  : ""}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Attandancetable;
