import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { IoMenu, IoClose } from "react-icons/io5";
import { AiOutlineLogout } from "react-icons/ai";
import { useDispatch } from "react-redux";
import { logout } from "../../redux/slices/AuthSlicer";
import { toast } from "react-toastify";

const NavBar = () => {
  const [sideMenu, setSideMenu] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const logOutHandler = async () => {
    
    dispatch(logout());
    setSideMenu(false);
    navigate("/login");
    toast.success("Successfully Logout");
  };

  const location = useLocation();

  return (
    <div
      className={`${
        location?.pathname === "/login" && "hidden"
      } container mx-auto max-w-[1280px] flex justify-between items-center  px-5 fixed top-0 bg-white`}
    >
      <div>
        <Link to={"/dashboard"}>
          <img
            src={require("../../assets/logo/logo.png")}
            alt="logo"
            className="object-cover w-[120px]"
          />
        </Link>
      </div>

      <div>
        {sideMenu ? (
          <>
            <IoClose
              onClick={() => setSideMenu(!sideMenu)}
              size={40}
              className="cursor-pointer relative"
            />
            <div className="flex gap-3 items-center border p-5 absolute shadow rounded right-7 md:right-5 bg-white">
              <div
                onClick={logOutHandler}
                className="cursor-pointer flex items-center gap-3 w-full hover:text-white hover:bg-[#F4881F] ease-in-out duration-300 px-5 p-2 rounded "
              >
                <AiOutlineLogout />
                <h1 className="">Logout</h1>
              </div>
            </div>
          </>
        ) : (
          <IoMenu
            onClick={() => setSideMenu(!sideMenu)}
            size={40}
            className="cursor-pointer relative"
          />
        )}
      </div>
    </div>
  );
};

export default NavBar;
