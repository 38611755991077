import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";

const AdminProtector = () => {
  const user = useSelector((state) => state.auth.userInfo);

  return user?.role === "Admin" ? <Outlet /> : <Navigate to="/login" replace />;
};

export default AdminProtector;
