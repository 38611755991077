import React from "react";
import { Link } from "react-router-dom";

const Cards = ({ data }) => {
  return (
    <div className="w-full md:w-[280px] md:h-[200px]">
      <div className="w-full h-full flex flex-col items-center justify-center">
        <Link to={data?.path} className="w-full md:w-[220px]">
          <div className="w-full overflow-hidden inline-flex shadow-2xl rounded-lg">
            <img
              src={data?.image}
              alt={`${data?.name}`}
            //   width={220}
              className="w-full object-cover cursor-pointer hover:scale-110 transition-transform ease-in-out duration-500"
            />
          </div>
        </Link>

        <h1 className="w-full md:ps-10 mt-2 text-sm font-semibold text-start">
          {data?.name}
        </h1>
      </div>
    </div>
  );
};

export default Cards;
