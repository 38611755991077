import React, { useState } from "react";
import ResultTable from "../../components/examResult/ResultTable";
import axiosInstance from "../../utils/Axios";
import { toast } from "react-toastify";
import { AddExamResult, GetABatch, GetAllBatches } from "../../utils/EndPoints";
import { useEffect } from "react";
import StudentLoader from "../../components/loading/StudentLoader";

const ExamResult = () => {
  const [selectedStudent, setSelectedStudent] = useState([]);
  const [classData, setClassData] = useState([]);
  const [yearData, setYearData] = useState([]);
  const [divisionData, setDivisionData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({
    class_name: "",
    batch_year: "",
    division: "",
    exam_name: "",
  });

  const inputChangeHandler = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axiosInstance.get(
        `${GetABatch}?batch_year=${form?.batch_year}&class_name=${form?.class_name}&division=${form?.division}&count=${100}`
      );
      setSelectedStudent(response?.data?.all_users);
    } catch (error) {
      console.log(error);
      toast.error(
        error?.response?.data?.Message || "Please check the student data"
      );
    } finally {
      setLoading(false);
    }
  };

  const resultHandler = async (data) => {
    setLoading(true);
    try {
      const response = await axiosInstance.post(
        `${AddExamResult}?batch_year=${form?.batch_year}&class_name=${form?.class_name}&division=${form?.division}`,
        data
      );
      toast.success(response?.data?.status || "Successfully Added");
      setSelectedStudent([]);
      setForm({
        class_name: "",
        batch_year: "",
        division: "",
        exam_name: "",
      });
    } catch (error) {
      console.log(error);
      toast.error("Something Went Wrong");
    }finally{
      setLoading(false);
    };
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    axiosInstance
      .get(GetAllBatches)
      .then((res) => {
        const uniqueClassNames = new Set(
          res?.data?.class_details.map((item) => item.class_name)
        );
        const uniqueYears = new Set(
          res?.data?.class_details.map((item) => item.batch_year)
        );
        const uniqueDivisions = new Set(
          res?.data?.class_details.map((item) => item.division)
        );

        // Convert Sets back to arrays
        const uniqueClassData = [...uniqueClassNames].map((className) => ({
          class_name: className,
        }));
        const uniqueYearData = [...uniqueYears].map((year) => ({
          batch_year: year,
        }));
        const uniqueDivisionData = [...uniqueDivisions].map((division) => ({
          division,
        }));

        setClassData(uniqueClassData);
        setYearData(uniqueYearData);
        setDivisionData(uniqueDivisionData);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [selectedStudent]);

  return (
    <div className="md:px-10 min-h-[480px]">
      <div className="flex items-center w-full justify-between ">
        <h1 className="font-semibold text-2xl font-mono text-[#F4881F]">
          Add Exam Result
        </h1>
      </div>

      <form onSubmit={submitHandler} action="" className="mt-10">
        <div className="flex flex-col gap-5 items-center justify-center">
          <div className="flex gap-5 items-center justify-center w-full">
            <div className="border rounded-lg w-full shadow-xl p-10 flex flex-col items-start">
              <label htmlFor="" className="mb-2 font-medium">
                Select Class
              </label>
              <select
                name="class_name"
                onChange={inputChangeHandler}
                value={form?.class_name}
                required
                id=""
                className="rounded text-sm w-full border border-[#F4881F]/30 p-2 focus:outline-none"
              >
                <option value="">Choose A Class</option>
                {classData.map((items, i) => (
                  <option key={i} value={items?.class_name}>
                    {items?.class_name}
                  </option>
                ))}
              </select>
            </div>

            <div className="border rounded-lg w-full shadow-xl p-10 flex flex-col items-start">
              <label htmlFor="" className="mb-2 font-medium">
                Select Division
              </label>
              <select
                name="division"
                onChange={inputChangeHandler}
                value={form?.division}
                required
                id=""
                className="rounded text-sm w-full border border-[#F4881F]/30 p-2 focus:outline-none"
              >
                <option value="">Choose A Division</option>
                {divisionData.map((items, i) => (
                  <option key={i} value={items?.division}>
                    {items?.division}
                  </option>
                ))}
              </select>
            </div>

            <div className="border rounded-lg w-full shadow-xl p-10 flex flex-col items-start">
              <label htmlFor="" className="mb-2 font-medium">
                Exam Name
              </label>
              <input
                name="exam_name"
                onChange={inputChangeHandler}
                value={form?.exam_name}
                required
                id=""
                className="rounded text-sm w-full border border-[#F4881F]/30 p-2 focus:outline-none"
              />
            </div>

            <div className="border rounded-lg w-full shadow-xl p-10 flex flex-col items-start">
              <label htmlFor="" className="mb-2 font-medium">
                Select A Year
              </label>
              <select
                name="batch_year"
                onChange={inputChangeHandler}
                value={form?.batch_year}
                required
                id=""
                className="rounded text-sm w-full border border-[#F4881F]/30 p-2 focus:outline-none"
              >
                <option value="">Choose A Year</option>
                {yearData.map((items, i) => (
                  <option key={i} value={items?.batch_year}>
                    {items?.batch_year}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <div className="mb-5 flex items-end justify-end">
          <button
            type="submit"
            className="bg-[#F4881F] p-2 font-semibold mt-5 text-sm px-10 rounded text-white hover:scale-105 ease-in-out duration-300"
          >
            Submit
          </button>
        </div>
      </form>

      <div className="w-full mt-10">
        <ResultTable
          data={selectedStudent}
          exam_name={form?.exam_name}
          resultHandler={resultHandler}
        />
      </div>
      {loading && <StudentLoader />}
    </div>
  );
};

export default ExamResult;
