import React, { useEffect, useState } from "react";
import axiosInstance from "../../utils/Axios";
import {
  GetExam,
  GetLeaderBoard,
  classNa,
  division,
  year,
} from "../../utils/EndPoints";
import { toast } from "react-toastify";

const LeaderBoard = () => {
  const [leaderData, setLeaderData] = useState();
  const [yearData, setYear] = useState([]);
  const [className, setClassName] = useState([]);
  const [divisionData, setDivision] = useState([]);
  const [exam, setExam] = useState([]);
  const [loading, setLoading] = useState(false);

  const [selectData, setSelectedData] = useState({
    class_name: "",
    division: "",
    batch_year: "",
    subject: "",
    exam_name: "",
  });

  const changeHandler = (e) => {
    setSelectedData({
      ...selectData,
      [e.target.name]: e.target.value,
    });
  };

  const selectSubjectHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    console.log(selectData);
    axiosInstance
      .get(
        `${GetLeaderBoard}?batch_year=${selectData?.batch_year}&class_name=${selectData?.class_name}&division=${selectData?.division}&subject=${selectData?.subject}&exam_name=${selectData?.exam_name}`
      )
      .then((res) => {
        console.log(res);
        setLeaderData(res?.data);
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
        console.log(error)
        if (error?.response?.status === 400) {
          setLeaderData();
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getLeader = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(GetLeaderBoard);
      setLeaderData(response?.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (selectData.batch_year && selectData.class_name && selectData.division) {
      axiosInstance
        .get(
          `${GetExam}?batch_year=${selectData.batch_year}&class_name=${selectData.class_name}&division=${selectData.division}`
        )
        .then((res) => {
          console.log(res?.data?.exams);
          setExam(res?.data?.exams);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [selectData.batch_year, selectData.class_name, selectData.division]);

  useEffect(() => {
    getLeader();
    window.scrollTo(0, 0);

    axiosInstance
      .get(year)
      .then((res) => {
        setYear(res?.data?.batch_year);
      })
      .catch((error) => {
        console.log(error);
      });
    axiosInstance
      .get(classNa)
      .then((res) => {
        setClassName(res?.data?.class_name);
      })
      .catch((error) => {
        console.log(error);
      });
    axiosInstance
      .get(division)
      .then((res) => {
        setDivision(res?.data?.division);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div className="md:px-10 min-h-[480px]">
      <div className="flex items-center w-full justify-center -mt-10">
        <h1 className="font-semibold text-2xl font-mono text-[#F4881F]">
          Leader Board
        </h1>
      </div>

      {/* Current Leader Board */}
      <div className="flex flex-col items-center justify-center mt-5 gap-2">
        <h1 className="font-semibold">
          <span className="font-bold uppercase">Batch :</span>{" "}
          {leaderData?.class_name} - <span>{leaderData?.division}</span> -{" "}
          <span>{leaderData?.batch_year}</span>
        </h1>
        <h1 className="font-semibold">
          <span className="font-bold uppercase">Subject :</span>{" "}
          {leaderData?.subject}
        </h1>
      </div>

      <div className="mt-5 flex flex-col items-center justify-center">
        <form
          onSubmit={selectSubjectHandler}
          className="w-full md:w-2/3 b flex flex-col items-center justify-center gap-5 mb-10 "
        >
          <div className="flex flex-col md:flex-row  w-full gap-3">
            {/* Batch */}
            <select
              name="class_name"
              onChange={changeHandler}
              id=""
              className="border bg-[#F4881F] text-white  rounded p-3 w-full focus:outline-none"
            >
              <option value="">Select a batch</option>
              {className?.map((items) => (
                <option
                  className="bg-white text-black"
                  key={items}
                  value={items}
                >
                  {items}
                </option>
              ))}
            </select>

            {/* Division */}
            <select
              name="division"
              onChange={changeHandler}
              id=""
              className="border bg-[#F4881F] text-white  rounded p-3 w-full focus:outline-none"
            >
              <option value="">Select a division</option>
              {divisionData?.map((items) => (
                <option
                  className="bg-white text-black"
                  key={items}
                  value={items}
                >
                  {items}
                </option>
              ))}
            </select>

            {/* Year */}
            <select
              name="batch_year"
              onChange={changeHandler}
              id=""
              className="border bg-[#F4881F] text-white  rounded p-3 w-full focus:outline-none"
            >
              <option value="">Select a year</option>
              {yearData?.map((items) => (
                <option
                  className="bg-white text-black"
                  key={items}
                  value={items}
                >
                  {items}
                </option>
              ))}
            </select>
          </div>

          <div className="flex flex-col md:flex-row  gap-3 w-full">
            {/* Subject */}
            <select
              name="subject"
              onChange={changeHandler}
              id=""
              className="border bg-[#F4881F] text-white  rounded p-3 w-full focus:outline-none"
            >
              <option value="">Select subject</option>
              <option className="bg-white text-black" value="physics">
                Physics{" "}
              </option>
              <option className="bg-white text-black" value="chemistry">
                Chemistry{" "}
              </option>

              <option className="bg-white text-black" value="maths">
                Maths{" "}
              </option>
            </select>

            {/* Exam Name */}
            <select
              name="exam_name"
              onChange={changeHandler}
              id=""
              className="border bg-[#F4881F] text-white  rounded p-3 w-full focus:outline-none"
            >
              <option>Select Exam</option>
              {exam?.map((items, i) => (
                <option key={i} className="bg-white text-black" value={items}>
                  {items}
                </option>
              ))}
            </select>

            <button className="border bg-yellow-400 text-white  rounded p-3 w-full focus:outline-none">
              Submit
            </button>
          </div>
        </form>

        {/* Subject Indicator */}
        <div className="flex gap-3">
          <div className="flex flex-col">
            <h1
              className={`mb-4 font-semibold uppercase text-bottom ${
                selectData?.subject === "physics" ? "bg-[#bfdbff]" : ""
              }  hover:bg-[#bfdbff] hover:text-white rounded-xl p-2 cursor-pointer px-5  `}
            >
              Physics
            </h1>
            <img
              src={require("../../assets/studentRank/Ellipse 12.png")}
              alt="pic1"
              className="w-30 object-contain rounded border shadow-lg"
            />
          </div>
          <div className="flex flex-col">
            <h1
              className={`mb-4 font-semibold uppercase text-top ${
                selectData?.subject === "chemistry" ? "bg-[#bfdbff]" : ""
              }  hover:bg-[#bfdbff] hover:text-white rounded-xl p-2 cursor-pointer px-5  `}
            >
              Chemistry
            </h1>
            <img
              src={require("../../assets/studentRank/Ellipse 12.png")}
              alt="pic1"
              className="w-40 object-contain mb-5 rounded border shadow-lg"
            />
          </div>
          <div className="flex flex-col">
            <h1
              className={`mb-4 font-semibold uppercase ${
                selectData?.subject === "maths" ? "bg-[#bfdbff]" : ""
              }  hover:bg-[#bfdbff] hover:text-white rounded-xl p-2 cursor-pointer px-5 `}
            >
              Maths
            </h1>
            <img
              src={require("../../assets/studentRank/Ellipse 12.png")}
              alt="pic1"
              className="w-30 object-contain rounded border shadow-lg"
            />
          </div>
        </div>

        {/* Leader Board Listing Part */}
        <div className="w-full md:w-1/3 mt-10 flex flex-col gap-3">
          {leaderData?.leaderboard ? (
            leaderData?.leaderboard.map((items) => (
              <div
                key={items?.admission_no}
                className="bg-[#D2E4FC] w-full flex justify-between items-center rounded-3xl"
              >
                <img
                  src={require("../../assets/studentRank/Ellipse 12.png")}
                  alt="smalIcon"
                  className="w-12"
                />
                <h1 className="text-blue-500 font-semibold">{items?.name}</h1>
                <h1 className="pe-5 text-blue-500 font-semibold">
                  {items?.mark} Mark{" "}
                </h1>
              </div>
            ))
          ) : (
            <div className="bg-[#D2E4FC] w-full flex justify-between items-center rounded-3xl">
              <img
                src={require("../../assets/studentRank/Ellipse 4.png")}
                alt="smalIcon"
                className="w-12"
              />
              <h1 className="text-blue-500 font-semibold">
                Result Not Published Yet
              </h1>
              <h1 className="text-blue-500 font-semibold"></h1>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default LeaderBoard;
