import React from "react";

const DailyClassTable = ({ data }) => {
  return (
    <>
      {data?.length > 0 ? (
        <div className="overflow-x-auto overflow-y-auto rounded-lg shadow-lg text-sm w-full">
          <table className="min-w-full bg-white ">
            <thead>
              <tr className="bg-[#F4881F] text-white w-full">
                <th className="py-2 px-4 border-b border-r">No.</th>
                <th className="py-2 px-4 border-b border-r">
                  Admission Number
                </th>
                <th className="py-2 px-4 border-b border-r">Date</th>
                <th className="py-2 px-4 border-b border-r">On Time</th>
                <th className="py-2 px-4 border-b border-r">Voice</th>
                <th className="py-2 px-4 border-b border-r">Camera</th>
                <th className="py-2 px-4 border-b border-r">Full Class</th>
                <th className="py-2 px-4 border-b border-r">Activities</th>
                <th className="py-2 px-4 border-b border-r">Engagement</th>
                <th className="py-2 px-4 border-b border-r">
                  Performance Percentage
                </th>
                <th className="py-2 px-4 border-b border-r">
                  Overall Performance
                </th>
                <th className="py-2 px-4 border-b border-r">Remarks</th>
              </tr>
            </thead>

            <tbody>
              {data?.map((row, i) => (
                <tr key={i}>
                  <td className="py-2 px-4 border-b border-r">{i + 1}</td>
                  <td className="py-2 px-4 border-b border-r">
                    {row?.admission_no}
                  </td>
                  <td className="py-2 px-4 border-b border-r">{row?.date}</td>
                  <td className="py-2 px-4 border-b border-r">
                    {row?.on_time ? "Yes" : "No"}
                  </td>
                  <td className="py-2 px-4 border-b border-r">
                    {row?.voice ? "Yes" : "No"}
                  </td>
                  <td className="py-2 px-4 border-b border-r">
                    {row?.camera ? "Yes" : "No"}
                  </td>
                  <td className="py-2 px-4 border-b border-r">
                    {row?.full_class ? "Yes" : "No"}
                  </td>
                  <td className="py-2 px-4 border-b border-r">
                    {row?.activities}
                  </td>
                  <td className="py-2 px-4 border-b border-r">
                    {row?.engagement ? "Yes" : "No"}
                  </td>
                  <td className="py-2 px-4 border-b border-r">
                    {row?.overall_performance_percentage}
                  </td>
                  <td className="py-2 px-4 border-b border-r">
                    {row?.overall_performance}
                  </td>
                  <td className="py-2 px-4 border-b border-r">
                    {row?.remarks}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="overflow-x-auto overflow-y-auto rounded-lg shadow-lg text-sm w-full">
          <table className="min-w-full bg-white ">
            <thead>
              <tr className="bg-[#F4881F] text-white w-full">
                <th className="py-2 px-4 border-b border-r">No Data</th>
              </tr>
            </thead>

            <tbody className="w-full">
              <div className="flex w-full items-center p-5 justify-center">
                <>No data available</>
              </div>
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};

export default DailyClassTable;
