import React, { useState } from "react";
import { useEffect } from "react";
import axiosInstance from "../../utils/Axios";
import { CreateClassLink, GetAllBatches } from "../../utils/EndPoints";
import { toast } from "react-toastify";
import StudentLoader from "../../components/loading/StudentLoader";

const DailyClassLink = () => {
  const [classData, setClassData] = useState([]);
  const [yearData, setYearData] = useState([]);
  const [divisionData, setDivisionData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({
    batch_year: "",
    class_name: "",
    division: "",
    link: "",
    topic: "",
  });

  const inputChangeHandler = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axiosInstance.post(
        CreateClassLink,
        form
      );
      if (response?.status === 201) {
        toast.success("Link Generate Successfully");
        setForm({
          batch_year: "",
          class_name: "",
          division: "",
          link: "",
          topic: "",
        });
      } else if (response?.status === 204) {
        toast.warning("No Content");
      }
    } catch (error) {
      console.log(error);
      toast.error("Please check the data");
    }finally{
      setLoading(false);
    };
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    axiosInstance
      .get(GetAllBatches)
      .then((res) => {
        const uniqueClassNames = new Set(
          res?.data?.class_details.map((item) => item.class_name)
        );
        const uniqueYears = new Set(
          res?.data?.class_details.map((item) => item.batch_year)
        );
        const uniqueDivisions = new Set(
          res?.data?.class_details.map((item) => item.division)
        );

        // Convert Sets back to arrays
        const uniqueClassData = [...uniqueClassNames].map((className) => ({
          class_name: className,
        }));
        const uniqueYearData = [...uniqueYears].map((year) => ({
          batch_year: year,
        }));
        const uniqueDivisionData = [...uniqueDivisions].map((division) => ({
          division,
        }));

        setClassData(uniqueClassData);
        setYearData(uniqueYearData);
        setDivisionData(uniqueDivisionData);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div className="md:px-10 min-h-[480px]">
      <div className="flex items-center w-full justify-center ">
        <h1 className="font-semibold text-2xl font-mono">Live Class Update</h1>
      </div>

      <div className="border border-[#F4881F]/30 shadow-lg rounded-xl mt-10 p-10 flex flex-col gap-5">
        <form onSubmit={submitHandler} action="" className="">
          <div className="flex flex-col gap-5 items-center justify-center">
            <div className="flex gap-5 items-center justify-center w-full">
              <div className="border rounded-lg w-full shadow-xl p-10 flex flex-col items-start">
                <label htmlFor="" className="mb-2 font-medium">
                  Select Class
                </label>
                <select
                  name="class_name"
                  onChange={inputChangeHandler}
                  value={form?.class_name}
                  id=""
                  className="rounded text-sm w-full border border-[#F4881F]/30 p-2 focus:outline-none"
                >
                  <option value="">Choose A Class</option>
                  {classData.map((items, i) => (
                    <option key={i} value={items?.class_name}>
                      {items?.class_name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="border rounded-lg w-full shadow-xl p-10 flex flex-col items-start">
                <label htmlFor="" className="mb-2 font-medium">
                  Select Division
                </label>
                <select
                  name="division"
                  onChange={inputChangeHandler}
                  value={form?.division}
                  id=""
                  className="rounded text-sm w-full border border-[#F4881F]/30 p-2 focus:outline-none"
                >
                  <option value="">Choose A Division</option>
                  {divisionData.map((items, i) => (
                    <option key={i} value={items?.division}>
                      {items?.division}
                    </option>
                  ))}
                </select>
              </div>

              <div className="border rounded-lg w-full shadow-xl p-10 flex flex-col items-start">
                <label htmlFor="" className="mb-2 font-medium">
                  Select A Year
                </label>
                <select
                  name="batch_year"
                  onChange={inputChangeHandler}
                  value={form?.batch_year}
                  id=""
                  className="rounded text-sm w-full border border-[#F4881F]/30 p-2 focus:outline-none"
                >
                  <option value="">Choose A Year</option>
                  {yearData.map((items, i) => (
                    <option key={i} value={items?.batch_year}>
                      {items?.batch_year}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="flex gap-5 items-center justify-center w-full">
              <div className="border rounded-lg w-full shadow-xl p-10 flex flex-col items-start">
                <label htmlFor="" className="mb-2 font-medium">
                  Topic Name
                </label>
                <input
                  name="topic"
                  onChange={inputChangeHandler}
                  value={form?.topic}
                  id=""
                  className="rounded text-sm w-full border border-[#F4881F]/30 p-2 focus:outline-none"
                />
              </div>

              <div className="border rounded-lg w-full shadow-xl p-10 flex flex-col items-start">
                <label htmlFor="" className="mb-2 font-medium">
                  Past a link
                </label>
                <input
                  name="link"
                  onChange={inputChangeHandler}
                  value={form?.link}
                  id=""
                  className="rounded text-sm w-full border border-[#F4881F]/30 p-2 focus:outline-none"
                />
              </div>
            </div>
          </div>
          <div className="mb-5 flex items-end justify-end">
            <button
              type="submit"
              // onClick={() => setView(!view)}
              className="bg-[#F4881F] p-2 font-semibold mt-5 text-sm px-10 rounded text-white hover:scale-105 ease-in-out duration-300"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
      {loading && <StudentLoader />}
    </div>
  );
};

export default DailyClassLink;
