import React from "react";

const ExamResultTable = ({ data, page, entries }) => {
 
  return (
    <div className="w-full flex flex-col md:flex-row gap-5 rounded-lg text-sm">
      <table className="w-full bg-white shadow-lg rounded-lg">
        <thead>
          <tr className="bg-[#F4881F] text-white">
            <th className="py-2 px-4 border-b border-r">No.</th>
            <th className="py-2 px-4 border-b border-r">AD No.</th>
            <th className="py-2 px-4 border-b border-r">Name</th>
            <th className="py-2 px-4 border-b border-r">Maths</th>
            <th className="py-2 px-4 border-b border-r">Chemistry</th>
            <th className="py-2 px-4 border-b border-r">Physics</th>
          </tr>
        </thead>

        <tbody>
          {data?.map((items, i) => (
            <tr key={i}>
              <td className="py-2 px-4 border-b border-r">
                {(page - 1) * entries + i + 1}
              </td>
              <td className="py-2 px-4 border-b border-r">
                {items?.admission_no}
              </td>
              <td className="py-2 px-4 border-b border-r">{items?.name}</td>
              <td
                className={`py-2 px-4 border-b border-r ${
                  items?.maths_status === "AB"
                    ? "text-red-500 font-semibold"
                    : items?.maths_status === "NA"
                    ? "text-yellow-500 font-semibold"
                    : "text-black"
                }`}
              >
                {items?.maths || items?.maths_status || (
                  <span className="font-semibold text-sky-500">NS</span>
                )}
              </td>
              <td
                className={`py-2 px-4 border-b border-r ${
                  items?.chemistry_status === "AB"
                    ? "text-red-500 font-semibold"
                    : items?.chemistry_status === "NA"
                    ? "text-yellow-500 font-semibold"
                    : "text-black"
                }`}
              >
                {items?.chemistry || items?.chemistry_status || (
                  <span className="font-semibold text-sky-500">NS</span>
                )}
              </td>
              <td
                className={`py-2 px-4 border-b border-r ${
                  items?.physics_status === "AB"
                    ? "text-red-500 font-semibold"
                    : items?.physics_status === "NA"
                    ? "text-yellow-500 font-semibold"
                    : "text-black"
                }`}
              >
                {items?.physics || items?.physics_status || (
                  <span className="font-semibold text-sky-500">NS</span>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ExamResultTable;
