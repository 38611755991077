import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import './App.css';
import LayOut from './style/LayOut';

import AuthProtector from './routes/AuthProtector';

// login routes
import Login from './pages/Login';

import AdminProtector from './routes/AdminProtector';
// Dashboard routes
import Dashboard from './pages/dashboard/Dashboard';
import Batch from './pages/batch/Batch';
import BatchDetailedView from './pages/batch/BatchDetaildView';
import StudentView from './pages/batch/StudentView';
import StudentReport from './pages/batch/StudentReport';
import StudentAttendance from './pages/batch/StudentAttendance';
import StudentMarkLIst from './pages/batch/StudentMarkLIst';
import StudentDailyClass from './pages/batch/StudentDailyClass';

// other Options
import AddStudent from './pages/addStudent/AddStudent';
import DailyClassUpdate from './pages/dailyClassUpdate/DailyClassUpdate';
import ExamResult from './pages/examResult/ExamResult';
import Attendance from './pages/attentance/Attandance';
import DailyClassLink from './pages/dailyClass/DailyClassLink';
import VideoClass from './pages/videoClass/VideoClass';
import Announcements from './pages/anouncements/Announcements';
import LeaderBoard from './pages/leaderBoard/LeaderBoard';
import HelpCenter from './pages/helpCenter/HelpCenter';
import ViewAttendance from './pages/attentance/ViewAttendance';
import ViewExamResult from './pages/examResult/ViewExamResult';
import VIewDailyClass from './pages/dailyClass/VIewDailyClass';
import ViewVideoClass from './pages/videoClass/ViewVideoClass';
import DailyClassUpdateView from './pages/dailyClassUpdate/DailyClassUpdateView';



function App() {
  return (
    <div className="App">
      <Router>
        <LayOut>
          <Routes>

            {/* Login part */}
            <Route path="/" element={<AuthProtector />}>
              <Route path="/login" element={<Login />} />
            </Route>

            {/* Body Part */}
            <Route path="/" element={<AdminProtector />} >
              <Route path='/dashboard' element={<Dashboard />} />

              {/* Batch */}
              <Route path='/dashboard/batch' element={<Batch />} />
              <Route path='/dashboard/batch/students/:year/:className/:division' element={<BatchDetailedView />} />
              <Route path='/dashboard/batch/students-details/:id' element={<StudentView />} />
              <Route path='/dashboard/batch/students-report/:id' element={<StudentReport />} />
              <Route path='/dashboard/batch/student-attendance/:id' element={<StudentAttendance />} />
              <Route path='/dashboard/batch/student-mark_list/:id' element={<StudentMarkLIst />} />
              <Route path='/dashboard/batch/student-daily_class_info/:id' element={<StudentDailyClass />} />

              {/* Add Student */}
              <Route path='/dashboard/add-student' element={<AddStudent />} />

              {/* Daily Updates */}
              <Route path='/dashboard/daily-class' element={<DailyClassUpdate />} />
              <Route path='/dashboard/daily-class/view' element={<DailyClassUpdateView />} />

              {/* Exam Publisher */}
              <Route path='/dashboard/exam-result' element={<ViewExamResult />} />
              <Route path='/dashboard/add-exam-result' element={<ExamResult />} />

              {/* Attendance */}
              <Route path='/dashboard/attendance' element={<ViewAttendance />} />
              <Route path='/dashboard/add-attendance' element={<Attendance />} />

              {/* Live Class Link */}
              <Route path='/dashboard/daily-class-update' element={<DailyClassLink />} />
              <Route path='/dashboard/view-daily-class' element={<VIewDailyClass />} />

              {/* video Class Link */}
              <Route path='/dashboard/view-video-class' element={<ViewVideoClass />} />
              <Route path='/dashboard/video-class' element={<VideoClass />} />

              {/* Announcement */}
              <Route path='/dashboard/announcements' element={<Announcements />} />

              {/* Leader Board */}
              <Route path='/dashboard/leader-board' element={<LeaderBoard />} />

              {/* Help Center */}
              <Route path='/dashboard/help-center' element={<HelpCenter />} />

            </Route>

          </Routes>
        </LayOut>
      </Router>
    </div>
  );
}

export default App;
