import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../utils/Axios";
import { blockStudentData } from "../../utils/EndPoints";
import { toast } from "react-toastify";

const Table = ({ data, batchData, page }) => {
  const navigate = useNavigate();

  const softDeleteHandler = async (selectData) => {
    try {
      if (selectData?.restricted === true) {
        const UpdatedData = {
          user_id: selectData?.id,
          restricted_status: false,
        };
        const response = await axiosInstance.post(
          blockStudentData,
          UpdatedData
        );
        toast.success(response?.data?.message);
        batchData();
      } else if (selectData?.restricted === false) {
        const UpdatedData = {
          user_id: selectData?.id,
          restricted_status: true,
        };
        const response = await axiosInstance.post(
          blockStudentData,
          UpdatedData
        );
        batchData();
        toast.success(response?.data?.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {Array.isArray(data) && data.length > 0 ? (
        <div className="overflow-x-auto overflow-y-auto rounded-lg shadow-lg text-sm">
          <table className="min-w-full bg-white ">
            <thead>
              <tr className="bg-[#F4881F] text-white">
                <th className="py-2 px-4 border-b border-r">Sl No.</th>
                <th className="py-2 px-4 border-b border-r">Name</th>
                <th className="py-2 px-4 border-b border-r">Phone Number</th>
                <th className="py-2 px-4 border-b border-r">Subject</th>
                <th className="py-2 px-4 border-b border-r">Options</th>
              </tr>
            </thead>

            <tbody>
              {data?.map((row, i) => (
                <tr key={i} className="capitalize">
                  <td className="py-2 px-4 border-b border-r">{(page-1)* 10 + i + 1}</td>
                  <td className="py-2 px-4 border-b border-r">{row.name}</td>
                  <td className="py-2 px-4 border-b border-r">
                    {row.phone_no}
                  </td>
                  <td className="py-2 px-4 border-b border-r">
                    {row.subjects}
                  </td>
                  <td className="py-2 px-4 border-b border-r flex gap-3 items-center justify-center">
                    <button
                      onClick={() =>
                        navigate(`/dashboard/batch/students-report/${row?.id}`)
                      }
                      className="bg-green-600 text-white text-sm p-2 px-5 rounded"
                    >
                      Report
                    </button>
                    <button
                      onClick={() =>
                        navigate(`/dashboard/batch/students-details/${row?.id}`)
                      }
                      className="bg-yellow-400 text-white text-sm p-2 px-5 rounded"
                    >
                      View
                    </button>
                    {row?.restricted === true ? (
                      <button
                        onClick={() => softDeleteHandler(row)}
                        className="bg-purple-400 text-white text-sm p-2 px-5 rounded"
                      >
                        UnBlock
                      </button>
                    ) : (
                      <button
                        onClick={() => softDeleteHandler(row)}
                        className="bg-red-500 text-white text-sm p-2 px-5 rounded"
                      >
                        Block
                      </button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div>
          <h1>No Data</h1>
        </div>
      )}
    </>
  );
};

export default Table;
