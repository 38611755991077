import React, { useEffect, useState } from "react";
import { LuCopyPlus } from "react-icons/lu";
import { toast } from "react-toastify";
import {
  CreateAnnouncementData,
  GetAllAnnouncement,
} from "../../utils/EndPoints";

import axiosInstance from "../../utils/Axios";
import AnnouncementTable from "../../components/announcement/AnnouncementTable";
import CreateAnnouncement from "../../components/announcement/modals/CreateAnnouncement";
import StudentLoader from "../../components/loading/StudentLoader";

const Announcements = () => {
  const [announcementData, setAnnouncementData] = useState([]);
  const [createModal, setCreateModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const initialData = async () => {
    setLoading(true);
    try {
      const res = await axiosInstance.get(GetAllAnnouncement);
      setAnnouncementData(res?.data?.announcements);
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data || "Something Went Wrong");
    } finally {
      setLoading(false);
    }
  };

  const submitHandler = async (data) => {
    setLoading(true);
    try {
      const response = await axiosInstance.post(CreateAnnouncementData, data);
      toast.success(response?.data?.message);
      setCreateModal(false);
      initialData();
    } catch (error) {
      console.log(error);
      toast.error("Something Went Wrong");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    initialData();
  }, []);

  return (
    <>
      <div className="md:px-10 min-h-[480px]">
        <div className="flex items-center w-full justify-between">
          <h1 className="font-semibold text-2xl font-mono">Announcements</h1>
          <div>
            <button
              onClick={() => setCreateModal(true)}
              className="bg-[#F4881F] text-white text-sm font-semibold p-2 py-2 px-8 shadow-lg  flex items-center justify-center gap-2 rounded hover:scale-105 ease-in-out duration-300"
            >
              <LuCopyPlus size={20} />
              Create Announcements
            </button>
          </div>
        </div>

        <div className="mt-10">
          <AnnouncementTable
            data={announcementData}
            initialData={initialData}
          />
        </div>
      </div>

      {createModal && (
        <CreateAnnouncement
          setModal={setCreateModal}
          submitHandler={submitHandler}
        />
      )}
      {loading && <StudentLoader />}
    </>
  );
};

export default Announcements;
