import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { MdModeEdit } from "react-icons/md";
import { MdDelete } from "react-icons/md";
import { toast } from "react-toastify";
import { DeleteABatch, UpdateABatch } from "../../utils/EndPoints";

import EditModal from "./modal/EditModal";
import axiosInstance from "../../utils/Axios";

const BatchCard = ({ data, batchData }) => {
  const navigate = useNavigate();
  const [editModal, setEditModal] = useState(false);
  const [submitModal, setSubmitModal] = useState(null);

  console.log(data);

  const editHandler = async () => {
    if (submitModal != null) {
      try {
        const response = await axiosInstance.put(UpdateABatch, submitModal);
        if (response?.status === 200) {
          setEditModal(false);
          toast.success(response?.data?.message || "Successfully Updated");
          batchData();
          setSubmitModal(null);
        }
      } catch (error) {
        console.log(error);
        toast.error(error?.response?.data?.Message || "Something Went Wrong");
      }
    }
  };

  const removeHandler = async () => {
    const isUserConfirmed = window.confirm("Are you sure you want to Delete?");
    if (isUserConfirmed) {
      try {
        const response = await axiosInstance.delete(
          `${DeleteABatch}?id=${data?.id}`
        );
        if (response?.status === 200) {
          toast.success(response?.data?.message);
          batchData();
        }
      } catch (error) {
        console.log(error);
        toast.error(error?.response?.data?.Message || "Something Went Wrong");
      }
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    editHandler();
  }, [submitModal]);

  return (
    <>
      <div className="w-full md:w-[260px] md:h-[160px]">
        <div className="w-full h-full flex flex-col items-center justify-center">
          <div className="w-full inline-flex flex-col items-center justify-center border rounded-t overflow-hidden  shadow-2xl rounded-lg">
            <div
              onClick={() =>
                navigate(
                  `/dashboard/batch/students/${data?.batch_year}/${data?.class_name}/${data?.division}`
                )
              }
              className="w-full p-3 py-4 flex items-center justify-center bg-gradient-to-b from-[#F4881F] to-[#FF0000]/80 cursor-pointer relative"
            >
              <h1 className="w-full uppercase text-3xl text-white font-semibold ">
                {data?.class_name} <span>{data?.division}</span>
              </h1>
              <h1 className="text-white text-lg absolute top-2 right-2">
                (<span className="text-[15px]">{data?.total_students}</span>)
              </h1>
            </div>

            <div className="flex gap-3 p-3 py-8">
              <div
                className="bg-white p-3 px-6 shadow-lg border rounded transition-transform hover:scale-110 ease-in-out duration-300 cursor-pointer"
                onClick={() => setEditModal(true)}
              >
                <MdModeEdit />
              </div>
              <div
                className="bg-white p-3 px-6 shadow-lg border rounded transition-transform hover:scale-110 ease-in-out duration-300 cursor-pointer"
                onClick={removeHandler}
              >
                <MdDelete />
              </div>
            </div>
          </div>
        </div>
      </div>

      {editModal && (
        <EditModal
          data={data}
          setModal={setEditModal}
          submitHandler={setSubmitModal}
        />
      )}
    </>
  );
};

export default BatchCard;
