import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const ResultTable = ({ data, exam_name, resultHandler }) => {
  const navigate = useNavigate();
  const [marks, setMarks] = useState({});
  const [statuses, setStatuses] = useState({}); // State to store statuses
  const [errors, setErrors] = useState({}); // State to store validation errors

  const handleMarkChange = (admissionNo, subject, value) => {
    setMarks((prevMarks) => ({
      ...prevMarks,
      [admissionNo]: {
        ...prevMarks[admissionNo],
        [subject]: value,
      },
    }));
  };

  const handleStatusChange = (admissionNo, subject, status) => {
    setStatuses((prevStatuses) => {
      const currentStatus = prevStatuses[admissionNo]?.[subject];
      const newStatus = currentStatus === status ? null : status;
      return {
        ...prevStatuses,
        [admissionNo]: {
          ...prevStatuses[admissionNo],
          [subject]: newStatus,
        },
      };
    });
  };

  const validate = () => {
    const validationErrors = {};

    data.forEach((person) => {
      person.subjects.split(",").forEach((subject) => {
        const mark = marks[person.admission_no]?.[subject];
        const status = statuses[person.admission_no]?.[subject];

        if (!mark && !status) {
          if (!validationErrors[person.admission_no]) {
            validationErrors[person.admission_no] = {};
          }
          toast.warn("Please provide a mark or select AB/NA");
          validationErrors[person.admission_no][subject] =
            "Please provide a mark or select AB/NA";
        }
      });
    });

    setErrors(validationErrors);
    return Object.keys(validationErrors).length === 0;
  };

  const createFormData = () => {
    return data.map((person) => {
      const subjects = person.subjects.split(",");
      const studentData = {
        admission_no: person.admission_no,
        exam_name: exam_name,
      };

      subjects.forEach((subject) => {
        studentData[subject] = marks[person.admission_no]?.[subject] || null;
        studentData[`${subject}_status`] =
          statuses[person.admission_no]?.[subject] || null;
      });

      return studentData;
    });
  };

  const submitHandler = () => {
    if (validate()) {
      const updatedFormData = createFormData();
      resultHandler(updatedFormData);
    } else {
      console.error("Validation failed", errors);
    }
  };

  return (
    <>
      <div className="overflow-x-auto overflow-y-auto rounded-lg shadow-lg text-sm">
        <table className="min-w-full bg-white">
          <thead>
            <tr className="bg-[#F4881F] text-white">
              <th className="py-2 px-4 border-b border-r">No</th>
              <th className="py-2 px-4 border-b border-r">Name</th>
              <th className="py-2 px-4 border-b border-r">Exam Name</th>
              <th className="py-2 px-4 border-b border-r">Subject</th>
            </tr>
          </thead>

          <tbody className="text-center">
            {data?.map((person,index) => (
              <tr key={person.id}>
                <td className="border text-wrap">{index+1}</td>
                <td className="border text-wrap">{person.name}</td>
                <td className="border">{exam_name}</td>
                <td className="flex flex-col  gap-3 items-start p-2 rounded border">
                  {person.subjects.split(",").map((subject, index) => (
                    <div key={subject} className="w-full ">
                      <input
                        className="p-2 border focus:outline-none text-center w-full"
                        type="text"
                        placeholder={`${subject}`}
                        value={marks[person.admission_no]?.[subject] || ""}
                        onChange={(e) =>
                          handleMarkChange(
                            person.admission_no,
                            subject,
                            e.target.value
                          )
                        }
                      />
                      <div className="flex items-center gap-2 mt-2">
                        <div className="flex gap-2 items-center">
                          <input
                            id={`checkbox-${index}-ab-${person.admission_no}`}
                            type="checkbox"
                            name={`checkbox-${person.admission_no}-${subject}`} // Unique group name
                            value="AB"
                            onChange={() =>
                              handleStatusChange(
                                person.admission_no,
                                subject,
                                "AB"
                              )
                            }
                            checked={
                              statuses[person.admission_no]?.[subject] === "AB"
                            }
                          />
                          <label
                            htmlFor={`checkbox-${index}-ab-${person.admission_no}`}
                          >
                            AB
                          </label>
                        </div>
                        <div className="flex gap-2 items-center">
                          <input
                            id={`checkbox-${index}-na-${person.admission_no}`}
                            type="checkbox"
                            name={`checkbox-${person.admission_no}-${subject}`} // Unique group name
                            value="NA"
                            onChange={() =>
                              handleStatusChange(
                                person.admission_no,
                                subject,
                                "NA"
                              )
                            }
                            checked={
                              statuses[person.admission_no]?.[subject] === "NA"
                            }
                          />
                          <label
                            htmlFor={`checkbox-${index}-na-${person.admission_no}`}
                          >
                            NA
                          </label>
                        </div>
                      </div>
                      {errors[person.admission_no]?.[subject] && (
                        <div className="text-red-600 text-sm mt-1">
                          {errors[person.admission_no][subject]}
                        </div>
                      )}
                    </div>
                  ))}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="flex items-end justify-end mt-5">
        <button
          onClick={submitHandler}
          className="bg-[#F4881F] p-2 px-5 rounded text-white"
        >
          Submit Result
        </button>
      </div>
    </>
  );
};

export default ResultTable;
