import React, { useState } from "react";
import { FaEdit } from "react-icons/fa";
import { MdDeleteForever } from "react-icons/md";
import ClassLinkEdit from "./modal/ClassLinkEdit";
import axiosInstance from "../../utils/Axios";
import { DeleteClassLink } from "../../utils/EndPoints";
import { toast } from "react-toastify";

const DailyClassLinkTable = ({ data, page, entries, cb }) => {
  const [editModal, setEditModal] = useState(false);
  const [selectedData, setSelectedData] = useState();

  const editHandler = (data) => {
    setSelectedData(data);
    setEditModal(true);
  };
  const deleteHandler = async (id) => {
    try {
      const conformation = window.confirm("Are You Sure About Delete This? ");
      if (conformation) {
        const response = await axiosInstance.delete(
          `${DeleteClassLink}?link_id=${id}`
        );
        toast.success( response?.data?.message||"Successfully Deleted");
        setEditModal(false);
        cb();
      }
    } catch (error) {
      console.log(error);
    } finally {
      console.log("Close");
    }
  };
  return (
    <div className="w-full flex flex-col md:flex-row gap-5 rounded-lg text-sm">
      <table className="w-full bg-white shadow-lg rounded-lg">
        <thead>
          <tr className="bg-[#F4881F] text-white">
            <th className="py-2 px-4 border-b border-r">No.</th>
            <th className="py-2 px-4 border-b border-r">Topic</th>
            <th className="py-2 px-4 border-b border-r">Action</th>
          </tr>
        </thead>

        <tbody>
          {data?.map((items, i) => (
            <tr key={i}>
              <td className="py-2 px-4 border-b border-r">
                {(page - 1) * entries + i + 1}
              </td>
              <td className="py-2 px-4 border-b border-r">{items?.topic}</td>
              <td className="py-2 flex items-center justify-center gap-3 px-4 border-b border-r">
                <FaEdit
                  onClick={() => editHandler(items)}
                  className="hover:text-[#F4881F] text-[20px]  cursor-pointer"
                />
                <MdDeleteForever
                  onClick={() => deleteHandler(items?.id)}
                  className="hover:text-[#F4881F] text-[20px]  cursor-pointer"
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {editModal && (
        <ClassLinkEdit setModal={setEditModal} data={selectedData} cb={cb} />
      )}
    </div>
  );
};

export default DailyClassLinkTable;
