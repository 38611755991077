import React from "react";
import { BatchReport } from "../../data/Batch";
import { Link, useParams } from "react-router-dom";

const StudentReport = () => {
  const {id} = useParams();

  return (
    <div className="md:px-10 md:h-[480px]">
      <div className="flex justify-between">
        <h1 className="text-2xl font-bold font-mono">Student Reports</h1>
      </div>

      <div className="flex mt-10 gap-2">
        {BatchReport.map((data) => (
          <div key={data?._id} className="w-full md:w-[280px] md:h-[200px]">
            <div className="w-full h-full flex flex-col items-start justify-center">
              <Link to={`${data?.path}/${id}`} className="w-full md:w-[220px]">
                <div className="w-full overflow-hidden inline-flex shadow-2xl rounded-lg">
                  <img
                    src={data?.image}
                    alt={`${data?.name}`}
                    className="w-full object-cover cursor-pointer hover:scale-110 transition-transform ease-in-out duration-500"
                  />
                </div>
              </Link>

              <h1 className="w-full ps-2 mt-2 text-sm font-semibold text-start">
                {data?.name}
              </h1>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default StudentReport;
