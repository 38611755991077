import React, { useEffect, useState } from "react";
import MarkListTable from "../../components/batch/MarkListTable";
import { useParams } from "react-router-dom";
import axiosInstance from "../../utils/Axios";
import { ExamResultStudentData } from "../../utils/EndPoints";
import StudentLoader from "../../components/loading/StudentLoader";

const StudentMarkList = () => {
  const [allData, setAllData] = useState([]); 
  const [filteredData, setFilteredData] = useState([]); 
  const [subjectData, setSubjectData] = useState([]);
  const { id } = useParams();
  const [loading, setLoading] = useState(false);

  const changeSubjectHandler = (e) => {
    const subject = e.target.value;
    const filterSubject = allData.find((items) => items?.subject === subject);

    if (filterSubject) {
      setFilteredData([...filterSubject?.data]);
    } else if( filterSubject === undefined){
      setFilteredData(allData)
    }
  };

  useEffect(() => {
    setLoading(true);
    axiosInstance
      .get(`${ExamResultStudentData}?user_id=${id}`)
      .then((res) => {
        const results = res?.data?.results;
        setAllData(results);
        setFilteredData(results); // Initial state contains all data
        const subjectData = results.map((items) => items?.subject);
        setSubjectData(subjectData);
      })
      .catch((error) => {
        console.log(error);
      }).finally(() => {
        setLoading(false);
      });
  }, [id]);

  return (
    <div className="md:px-10 md:min-h-[480px]">
      <div className="flex items-center justify-between">
        <h1 className="text-2xl font-bold font-mono">Exam Result</h1>
        <div className="flex flex-col items-start ">
          <label htmlFor="" className="text-sm mb-2 font-medium">
            Select A Subject
          </label>
          <select
            name="subject"
            onChange={changeSubjectHandler}
            id=""
            className="text-sm border p-2 px-8 rounded text-white bg-[#F4881F]/80 focus:outline-none cursor-pointer"
          >
            <option value="fullData" className="bg-white text-black">
              Select a option
            </option>
            {subjectData.map((items, i) => (
              <option
                key={i}
                value={items}
                className="bg-white text-black capitalize"
              >
                {items}
              </option>
            ))}
          </select>
        </div>
      </div>

      {/* Render MarkListTable with filtered data */}
      <div className="mt-5">
        <MarkListTable data={filteredData} allData={allData} />
      </div>
      {loading && <StudentLoader />}
    </div>
  );
};

export default StudentMarkList;
