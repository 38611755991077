import React, { useState } from "react";
import UpdateAnnouncement from "./modals/UpdateAnnouncement";
import axiosInstance from "../../utils/Axios";
import { toast } from "react-toastify";
import { DeleteAnnouncementData } from "../../utils/EndPoints";

const AnnouncementTable = ({ data, initialData }) => {
  const [editModal, setEditModal] = useState(false);
  const [selectedData, setSelectedData] = useState({});

  const editAnnouncement = (row) => {
    setSelectedData(row);
    setEditModal(true);
  };

  const deleteAnnouncement = async (row) => {
    console.log(row)
    const deleteItem = window.confirm("Are You Sure About Delete This?");

    if (deleteItem) {
      try {
        const response = await axiosInstance.delete(
          `${DeleteAnnouncementData}?upload_date=${row?.upload_date}`
        );
        toast.success(response?.data?.message);
        initialData();
      } catch (error) {
        console.log(error);
        toast.error(error?.response?.data?.Message || "Something Went Wrong");
      }
    }
  };

  return (
    <>
      <div className="overflow-x-auto overflow-y-auto rounded-lg shadow-lg text-sm">
        <table className="min-w-full bg-white ">
          <thead>
            <tr className="bg-[#F4881F] text-white">
              <th className="py-2 px-4 border-b border-r">Sl No.</th>
              <th className="py-2 px-4 border-b border-r">Announcement</th>
              <th className="py-2 px-4 border-b border-r">Date</th>
              <th className="py-2 px-4 border-b border-r">Options</th>
            </tr>
          </thead>

          <tbody>
            {data?.map((row, i) => (
              <tr key={row?.id}>
                <td className="py-2 px-4 border-b border-r">{i + 1}</td>
                <td className="py-2 px-4 border-b border-r">
                  {row.announcement}
                </td>
                <td className="py-2 px-4 border-b border-r">
                  {row.upload_date}
                </td>
                <td className="py-2 px-4 border-b border-r flex gap-3 items-center justify-center">
                  <button
                    onClick={() => editAnnouncement(row)}
                    className="bg-green-600 text-white text-sm p-2 px-5 rounded"
                  >
                    Edit
                  </button>
                  <button
                    onClick={() => deleteAnnouncement(row)}
                    className="bg-red-700 text-white text-sm p-2 px-5 rounded"
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {editModal && (
        <UpdateAnnouncement
          setModal={setEditModal}
          data={selectedData}
          initialData={initialData}
        />
      )}
    </>
  );
};

export default AnnouncementTable;
