import React from "react";

const MarkListTable = ({ data, allData }) => {
  return (
    <div className="overflow-x-auto overflow-y-auto rounded-lg shadow-lg text-sm">
      <table className="min-w-full bg-white ">
        <thead>
          <tr className="bg-[#F4881F] text-white">
            <th className="py-2 px-4 border-b border-r">No.</th>
            <th className="py-2 px-4 border-b border-r">Exam Name</th>
            <th className="py-2 px-4 border-b border-r">
              {data[0]?.data ? "Total No. Exams" : "Mark"}
            </th>
          </tr>
        </thead>

        <tbody>
          {data[0]?.data
            ? allData?.map((row, i) => (
                <tr key={i}>
                  <td className="py-2 px-4 border-b border-r">{i + 1}</td>
                  <td className="py-2 px-4 border-b border-r">
                    {row?.subject}
                  </td>
                  <td className="py-2 px-4 border-b border-r">
                    {row?.data?.length}
                  </td>
                </tr>
              ))
            : data?.map((row, i) => (
                <tr key={i}>
                  <td className="py-2 px-4 border-b border-r">{i + 1}</td>
                  <td className="py-2 px-4 border-b border-r">
                    {row?.exam_name}
                  </td>
                  <td className="py-2 px-4 border-b border-r">{row?.marks}</td>
                </tr>
              ))}
        </tbody>
      </table>
    </div>
  );
};

export default MarkListTable;
