import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  DeleteStudentData,
  GetASingleStudent,
  updateStudentData,
} from "../../utils/EndPoints";
import { toast } from "react-toastify";

import axiosInstance from "../../utils/Axios";
import StudentInfoEditModal from "../../components/batch/modal/StudentInfoEditModal";
import StudentLoader from "../../components/loading/StudentLoader";

const StudentView = () => {
  const navigate = useNavigate();
  const [modal, setModal] = useState(false);
  const [data, setData] = useState([]);
  const { id } = useParams();
  const [loading, setLoading] = useState(false);

  const deleteHandler = async () => {
    const deleteRequest = window.confirm("Are you sure you want to Delete?");

    if (deleteRequest) {
      try {
        const response = await axiosInstance.delete(
          `${DeleteStudentData}?id=${id}`
        );
        if (response?.status === 200) {
          toast.success(response?.data?.Message);
          navigate("/dashboard/batch");
        }
      } catch (error) {
        console.log(error);
        toast.error("Something Went Wrong");
      }
    }
  };

  const submitHandler = async (data) => {
    setLoading(true);
    try {
      const response = await axiosInstance.put(
        `${updateStudentData}?id=${id}`,
        data
      );
      console.log(response);
      toast.success(response?.data?.message || "Successfully Completed");
      setModal(false);
      studentViewData();
    } catch (error) {
      console.log(error);
      toast.error("Something Went Wrong");
    }finally{
      setLoading(false);
    };
  };

  // Initial Data Fetching
  const studentViewData = async () => {
    setLoading(true);
    try {
      const res = await axiosInstance.get(`${GetASingleStudent}?id=${id}`);
      setData(res?.data);
    } catch (error) {
      console.log(error);
      toast.error("Something Went Wrong");
    }finally{
      setLoading(false);
    };
  };

  useEffect(() => {
    studentViewData();
  }, []);

  return (
    <>
      <div className="p-10 bg-[#FAFAFA] border border-[#F4881F]/30 rounded-lg shadow-lg">
        <div className="flex justify-between">
          <h1 className="text-2xl font-bold font-mono">Student Information</h1>
          <div className="flex gap-2">
            <button
              onClick={deleteHandler}
              className="bg-red-500 text-white text-sm font-semibold p-2  px-8  flex items-center justify-center gap-2 rounded hover:scale-105 ease-in-out duration-300"
            >
              Delete
            </button>
            <button
              onClick={() => setModal(true)}
              className="bg-yellow-400 text-white text-sm font-semibold p-2  px-8  flex items-center justify-center gap-2 rounded hover:scale-105 ease-in-out duration-300"
            >
              Edit
            </button>
          </div>
        </div>

        <div className="w-full h-full mt-10 flex pb-4 capitalize">
          <div className="justify-center p-10 hidden md:flex w-[350px]">
            <img
              src={require("../../assets/profileicon.png")}
              alt="ProfilePic"
              className="w-[300px] object-contain"
            />
          </div>

          <div className="p-5 bg-white shadow-lg rounded w-full font-mono">
            <div className="flex">
              <div className="border w-full flex flex-col items-start p-5">
                <h1>Name</h1>
                <h2 className="font-semibold text-md capitalize">
                  {data?.name}
                </h2>
              </div>
              <div className="border w-full flex flex-col items-start p-5">
                <h1>Phone</h1>
                <h2 className="font-semibold text-md">{data?.phone_no}</h2>
              </div>
              <div className="border w-full flex flex-col items-start p-5">
                <h1>Email</h1>
                <h2 className="font-semibold text-md lowercase">{data?.email_id}</h2>
              </div>
            </div>

            <div className="flex">
              <div className="border w-full flex flex-col items-start p-5">
                <h1>Admission Number</h1>
                <h2 className="font-semibold text-md">{data?.admission_no}</h2>
              </div>
              <div className="border w-full flex flex-col items-start p-5">
                <h1>School Name</h1>
                <h2 className="font-semibold text-md">{data?.school_name}</h2>
              </div>
              <div className="border w-full flex flex-col items-start p-5">
                <h1>Class Name</h1>
                <h2 className="font-semibold text-md">{data?.class_name}</h2>
              </div>
            </div>

            <div className="flex">
              <div className="border w-full flex flex-col items-start p-5">
                <h1>Division</h1>
                <h2 className="font-semibold text-md uppercase">
                  {data?.division}
                </h2>
              </div>
              <div className="border w-full flex flex-col items-start p-5">
                <h1>Batch Year</h1>
                <h2 className="font-semibold text-md">{data?.batch_year}</h2>
              </div>
              <div className="border w-full flex flex-col items-start p-5">
                <h1>Subject</h1>
                <h2 className="font-semibold text-md">{data?.subjects}</h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      {modal && (
        <StudentInfoEditModal
          setModal={setModal}
          data={data}
          submitHandler={submitHandler}
        />
      )}
       {loading && <StudentLoader />}
    </>
  );
};

export default StudentView;
