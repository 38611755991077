import React, { useState } from "react";
import { IoClose } from "react-icons/io5";
import axiosInstance from "../../../utils/Axios";
import { toast } from "react-toastify";
import { UpdateAnnouncementData } from "../../../utils/EndPoints";

const UpdateAnnouncement = ({ setModal, data, initialData }) => {
  const [form, setForm] = useState({
    upload_date: data?.upload_date,
    announcement: data?.announcement,
  });

  const inputChangeHandler = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const submitFormData = async(e) => {
    e.preventDefault();
    try {
        const response = await axiosInstance.put(UpdateAnnouncementData,form);
        toast.success(response?.data?.message);
        setModal(false);
        initialData();
      } catch (error) {
        console.log(error);
        toast.error(error?.response?.data?.Message || "Something Went Wrong");
      }
  };

  

  return (
    <div className="fixed top-0 left-0 w-full h-screen overflow-auto bg-black/50 flex items-center justify-center z-50 ease-in-out duration-200">
      <div className="relative  shadow-xl bg-white mt-60  md:mt-0 md:w-1/2 rounded-lg  md:p-10 md:px-14 m-5">
        <h1 className="font-bold text-center text-xl text-primary_colors font-mono">
          Update Announcement
        </h1>

        <IoClose
          onClick={() => setModal(false)}
          className="absolute right-3 top-3 rounded-full border border-black cursor-pointer"
        />
        <div className="flex flex-col  w-full mt-5 border border-[#F4881F]/40 p-5 shadow-lg rounded-lg">
          <form
            action=""
            onSubmit={submitFormData}
            className="flex flex-col gap-5 items-center justify-center my-5"
          >
            <div className="flex flex-col items-center justify-center gap-3 w-full">
              <div className="flex flex-col items-start w-full">
                <label htmlFor="" className="mb-2 text-sm font-medium">
                  Upload Date
                </label>
                <input
                  type="date"
                  name="upload_date"
                  required
                  onChange={inputChangeHandler}
                  value={form?.upload_date}
                  className="p-2 border text-sm w-full rounded focus:outline-none"
                />
              </div>

              <div className="flex flex-col items-start w-full">
                <label htmlFor="" className="mb-2 text-sm font-medium">
                  Announcement
                </label>
                <textarea
                  type="text"
                  name="announcement"
                  required
                  onChange={inputChangeHandler}
                  value={form?.announcement}
                  placeholder="Your Content Here"
                  className="p-2 border text-sm w-full rounded focus:outline-none"
                />
              </div>
            </div>

            <div className="w-full flex items-center justify-center py-2 gap-3">
              <button
                type="submit"
                className="bg-[#F4881F]  p-2 px-5 rounded text-white hover:scale-105 ease-in-out duration-200"
              >
                Submit
              </button>
              <button
                onClick={() => setModal(false)}
                type="button"
                className="bg-red-500  p-2 px-5 rounded text-white hover:scale-105 ease-in-out duration-200"
              >
                cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default UpdateAnnouncement;
