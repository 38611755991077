import React, { useState } from "react";
import { IoClose } from "react-icons/io5";
import { toast } from "react-toastify";
import axiosInstance from "../../../utils/Axios";
import { classNa, division, year } from "../../../utils/EndPoints";
import Select from "react-select";

const StudentInfoEditModal = ({ setModal, submitHandler, data }) => {
  const [selectedOptions, setSelectedOptions] = useState();
  const [classData, setClassData] = useState([]);
  const [yearData, setYearData] = useState([]);
  const [divisionData, setDivisionData] = useState([]);
  
  console.log(data)
  const [form, setForm] = useState({
    name: data?.name,
    admission_no: data?.admission_no,
    phone_no: data?.phone_no,
    email_id: data?.email_id,
    old_batch_year: data?.batch_year,
    old_class_name: data?.class_name,
    old_division: data?.division,
    new_batch_year: data?.batch_year,
    new_class_name: data?.class_name,
    new_division: data?.division,
    subjects: data?.subjects,
    school_name: data?.school_name,
  });

  //Adding the employee to the team
  const selectHandler = (selectedOption) => {
    setSelectedOptions(selectedOption);
    const resultString = selectedOption.map((item) => item.label).join(",");

    setForm({
      ...form,
      subjects: resultString,
    });
  };

  // Select option array
  const options = [
    {
      value: "Physics",
      label: "physics",
    },
    {
      value: "Chemistry",
      label: "chemistry",
    },
    {
      value: "Maths",
      label: "maths",
    },
  ];

  const inputChangeHandler = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const submitFormData = (e) => {
    e.preventDefault();
    submitHandler(form);
  };

  // Data For Select option
  const classDrop = async () => {
    try {
      const response = await axiosInstance.get(classNa);
      setClassData(response?.data?.class_name);
    } catch (error) {
      console.log(error);
      toast.error("Something Went Wrong");
    }
  };

  const yearDrop = async () => {
    try {
      const response = await axiosInstance.get(year);
      setYearData(response?.data?.batch_year);
    } catch (error) {
      console.log(error);
      toast.error("Something Went Wrong");
    }
  };

  const divisionDrop = async () => {
    try {
      const response = await axiosInstance.get(division);
      setDivisionData(response?.data?.division);
    } catch (error) {
      console.log(error);
      toast.error("Something Went Wrong");
    }
  };

  useState(() => {
    classDrop();
    yearDrop();
    divisionDrop();
  }, []);

  return (
    <div className="fixed top-0 left-0 w-full h-screen overflow-auto bg-black/50 flex items-center justify-center z-50 ease-in-out duration-200">
      <div className="relative  shadow-xl bg-white mt-60  md:mt-0 md:w-1/2 rounded-lg  md:p-10 md:px-14 m-5">
        <h1 className="font-bold text-center text-xl text-primary_colors font-mono">
          Update Student
        </h1>

        <IoClose
          onClick={() => setModal(false)}
          className="absolute right-3 top-3 rounded-full border border-black cursor-pointer"
        />

        <div className="flex flex-col  w-full mt-5 border border-[#F4881F]/40 p-5 shadow-lg rounded-lg">
          <form
            onSubmit={submitFormData}
            action=""
            className="w-full flex flex-col gap-5 px-10"
          >
            <div className="flex items-start w-full gap-2 md:gap-5">
              <div className="flex flex-col items-start w-full">
                <label htmlFor="" className="mb-2 font-medium">
                  Full name*
                </label>
                <input
                  type="text"
                  name="name"
                  onChange={inputChangeHandler}
                  value={form?.name}
                  required
                  className="rounded text-sm w-full border border-[#F4881F]/30 p-2 focus:outline-none"
                />
              </div>
              <div className="flex flex-col items-start w-full">
                <label htmlFor="" className="mb-2 font-medium">
                  Phone Number*
                </label>
                <input
                  type="text"
                  name="phone_no"
                  onChange={inputChangeHandler}
                  value={form?.phone_no}
                  required
                  className="rounded text-sm w-full border border-[#F4881F]/30 p-2 focus:outline-none"
                />
              </div>
            </div>

            <div className="flex items-start w-full gap-2 md:gap-5">
              <div className="flex flex-col items-start w-full">
                <label htmlFor="" className="mb-2 font-medium">
                  School Name*
                </label>
                <input
                  type="text"
                  name="school_name"
                  onChange={inputChangeHandler}
                  value={form?.school_name}
                  required
                  className="rounded text-sm w-full border border-[#F4881F]/30 p-2 focus:outline-none"
                />
              </div>
              <div className="flex flex-col items-start w-full">
                <label htmlFor="" className="mb-2 font-medium">
                  Admission Number*
                </label>
                <input
                  type="text"
                  name="admission_no"
                  onChange={inputChangeHandler}
                  value={form?.admission_no}
                  readOnly
                  required
                  className="rounded text-sm w-full border border-[#F4881F]/30 p-2 focus:outline-none"
                />
              </div>
            </div>

            <div className="flex items-start w-full gap-2 md:gap-5">
              <div className="flex flex-col items-start w-full">
                <label htmlFor="" className="mb-2 font-medium ">
                  Class*
                </label>
                <select
                  name="new_class_name"
                  onChange={inputChangeHandler}
                  value={form?.class_name}
                  id=""
                  className="rounded text-sm w-full border border-[#F4881F]/30 p-2 focus:outline-none"
                >
                  <option value="">Select Class</option>
                  {classData.map((items, i) => (
                    <option key={i} value={items}>
                      {items}
                    </option>
                  ))}
                </select>
              </div>

              <div className="flex flex-col items-start w-full">
                <label htmlFor="" className="mb-2 font-medium">
                  Division*
                </label>
                <select
                  name="new_division"
                  onChange={inputChangeHandler}
                  value={form?.division}
                  id=""
                  className="rounded text-sm w-full border border-[#F4881F]/30 p-2 focus:outline-none"
                >
                  <option value="">Select Class</option>

                  {divisionData.map((items, i) => (
                    <option key={i} value={items}>
                      {items}
                    </option>
                  ))}
                </select>
              </div>

              <div className="flex flex-col items-start w-full">
                <label htmlFor="" className="mb-2 font-medium ">
                  Year*
                </label>
                <select
                  name="new_batch_year"
                  onChange={inputChangeHandler}
                  value={form?.batch_year}
                  id=""
                  className="rounded text-sm w-full border border-[#F4881F]/30 p-2 focus:outline-none"
                >
                  <option value="">Select Year</option>
                  {yearData.map((items, i) => (
                    <option key={i} value={items}>
                      {items}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="flex items-start w-full gap-2 md:gap-5">
              <div className="flex flex-col items-start w-full">
                <label htmlFor="" className="mb-2 text-sm font-medium">
                  Subject
                </label>
                <Select
                  options={options}
                  value={selectedOptions}
                  onChange={selectHandler}
                  isMulti={true}
                  defaultInputValue={form?.subjects}
                  className="rounded text-sm w-full border  focus:outline-none"
                />
              </div>
            </div>

            <div>
              <button className="bg-[#F4881F] p-2 text-sm px-5 rounded text-white hover:scale-105 ease-in-out duration-300">
                Update Student
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default StudentInfoEditModal;
