import React from "react";
import NavBar from "../components/layOut/NavBar";
import Footer from "../components/layOut/Footer";

const LayOut = ({ children }) => {
  return (
    <div>
      <nav className="w-full bg-white flex items-center justify-center">
        <NavBar />
      </nav>
      <section className="container mx-auto p-5 max-w-[1280px] h-full mt-28">
        {children}
      </section>
      <footer className="bg-white shadow">
        <Footer />
      </footer>
    </div>
  );
};

export default LayOut;
