import React from "react";

const AttandanceTable = ({ data }) => {
  return (
    <div className="w-full flex flex-col md:flex-row gap-5 rounded-lg text-sm">
      <table className="w-full bg-white shadow-lg rounded-lg">
        <thead>
          <tr className="bg-[#F4881F] text-white">
            <th className="py-2 px-4 border-b border-r">Present Date</th>
          </tr>
        </thead>

        <tbody>
          {data?.present_days?.map((row, i) => (
            <tr key={i}>
              <td className="py-2 px-4 border-b border-r">
                {row?.year} / {row?.month} / {row?.day}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <table className="w-full bg-white shadow-lg rounded-lg">
        <thead>
          <tr className="bg-[#F4881F] text-white">
            <th className="py-2 px-4 border-b border-r">Absent Date</th>
          </tr>
        </thead>

        <tbody>
          {data?.absent_days?.map((row, i) => (
            <tr key={i}>
              <td className="py-2 px-4 border-b border-r">
                {row?.year} / {row?.month} / {row?.day}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AttandanceTable;
