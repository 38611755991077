import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  AttendanceStudentDate,
  AttendanceStudentReport,
} from "../../utils/EndPoints";

import AttandanceTable from "../../components/batch/AttendanceTable";
import axiosInstance from "../../utils/Axios";
import StudentLoader from "../../components/loading/StudentLoader";
import { format } from "date-fns";
import { toast } from "react-toastify";

const StudentAttendance = () => {
  const [data, setData] = useState();
  const [date, setDate] = useState();
  const [subject, setSubject] = useState();
  const [Attendance, setAttendance] = useState({});
  const { id } = useParams();
  const [loading, setLoading] = useState(false);

  const attendance = async (monthYear) => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(
        `${AttendanceStudentReport}?user_id=${id}&month_year_number=${monthYear}&subject=${subject}`
      );
      setAttendance(response?.data?.attendance_result);
      toast.success(response?.data?.message);
      console.log(response);
    } catch (error) {
      toast.error(error?.response?.data?.message);
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleDateChange = (e) => {
    // Extract month and year from the selected date
    const selectedDate = new Date(e.target.value + "-01"); // Append "-01" to ensure it's the first day of the month
    const monthYear = format(selectedDate, "MM/yyyy");
    setDate(selectedDate);
  };

  const handleFilter = () => {
    const monthYear = format(date, "MM/yyyy");
    attendance(monthYear);
  };

  useEffect(() => {
    if (subject && date) {
      const monthYear = format(date, "MM/yyyy");
      attendance(monthYear);
    }
  }, [date, subject]);

  return (
    <div className="md:px-10 md:min-h-[480px]">
      <div className="flex items-center justify-between">
        <h1 className="text-2xl font-bold font-mono">Student Attendance</h1>
        <div className="flex gap-5 items-start ">
          <div className="flex flex-col items-start ">
            <label htmlFor="" className="text-sm mb-2 font-medium">
              Select A Subject
            </label>
            <select
              name="date"
              id=""
              onChange={(e) => setSubject(e.target.value)}
              className="text-sm border p-2 rounded text-white bg-[#F4881F]/80 focus:outline-none cursor-pointer"
            >
              <option value="" className="bg-white text-black">
                Select A Subject
              </option>
              <option value="physics" className="bg-white text-black">
                Physics
              </option>
              <option value="chemistry" className="bg-white text-black">
                Chemistry
              </option>
              <option value="maths" className="bg-white text-black">
                Maths
              </option>
            </select>
          </div>
          <div className="flex flex-col items-start ">
            <label htmlFor="" className="text-sm mb-2 font-medium">
              Select A Month and Year
            </label>
            <input
              type="month"
              name="date"
              id=""
              onChange={handleDateChange}
              className="text-sm border p-2 rounded text-white bg-[#F4881F]/80 focus:outline-none cursor-pointer"
            />
          </div>
          {/* <button
            onClick={handleFilter}
            className="text-sm bg-blue-500 text-white p-2 rounded hover:bg-blue-600 focus:outline-none cursor-pointer"
          >
            Filter
          </button> */}
        </div>
      </div>

      <div className="mt-5">
        <AttandanceTable data={Attendance} />
      </div>
      {loading && <StudentLoader />}
    </div>
  );
};

export default StudentAttendance;
