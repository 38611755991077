import React, { useState } from "react";
import { IoClose } from "react-icons/io5";
import axiosInstance from "../../../utils/Axios";
import {  UpdateClassLink } from "../../../utils/EndPoints";
import { toast } from "react-toastify";

const ClassLinkEdit = ({ setModal, data, cb }) => {
  const [formData, setFormData] = useState({
    class_name: data?.class_name,
    batch_year: data?.batch_year,
    division: data?.division,
    link: data?.link,
    topic: data?.topic,
  });

  const submitFormData = async (e) => {
    e.preventDefault();
    try {
      const response = await axiosInstance.put(
        `${UpdateClassLink}?link_id=${data?.id}`,
        formData
      );
      toast.success(response?.data?.message || "Successfully Updated");
      setModal(false);
      cb();
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message);
    } finally {
      console.log("close");
    }
  };
  const inputChangeHandler = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div className="fixed top-0 left-0 w-full h-screen overflow-auto bg-black/50 flex items-center justify-center z-50 ease-in-out duration-200">
      <div className="relative  shadow-xl bg-white mt-60  md:mt-0 md:w-1/2 rounded-lg  md:p-10 md:px-14 m-5">
        <h1 className="font-bold text-center text-xl text-primary_colors font-mono">
          Edit Link
        </h1>

        <IoClose
          onClick={() => setModal(false)}
          className="absolute right-3 top-3 rounded-full border border-black cursor-pointer"
        />
        <div className="flex flex-col  w-full mt-5 border border-[#F4881F]/40 p-5 shadow-lg rounded-lg">
          <form
            action=""
            onSubmit={submitFormData}
            className="flex flex-col gap-5 items-center justify-center my-5"
          >
            <div className="flex flex-col items-center justify-center gap-3 w-full">
              <div className="flex flex-col items-start w-full">
                <label htmlFor="" className="mb-2 text-sm font-medium">
                  Topic
                </label>
                <input
                  type="text"
                  name="topic"
                  required
                  value={formData.topic}
                  onChange={inputChangeHandler}
                  className="p-2 border text-sm w-full rounded focus:outline-none"
                />
              </div>
              <div className="flex flex-col items-start w-full">
                <label htmlFor="" className="mb-2 text-sm font-medium">
                  Link
                </label>
                <input
                  type="text"
                  name="link"
                  required
                  value={formData.link}
                  onChange={inputChangeHandler}
                  className="p-2 border text-sm w-full rounded focus:outline-none"
                />
              </div>
            </div>

            <div className="w-full flex items-center justify-center py-2 gap-3">
              <button
                type="submit"
                className="bg-[#F4881F]  p-2 px-5 rounded text-white hover:scale-105 ease-in-out duration-200"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ClassLinkEdit;
