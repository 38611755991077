import React, { useEffect, useState } from "react";
import Table from "../../components/batch/Table";
import { useParams } from "react-router-dom";
import axiosInstance from "../../utils/Axios";
import { GetABatch } from "../../utils/EndPoints";
import Pagination from "../../components/Pagination";
import StudentLoader from "../../components/loading/StudentLoader";
import { toast } from "react-toastify";

const BatchDetailedView = () => {
  const [data, setData] = useState([]);
  const { year, className, division } = useParams();
  const [page, setPage] = useState(1);
  const [entries, setEntries] = useState(100);
  const [loading, setLoading] = useState(false);

  // initial data fetching
  const selectedBatch = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(
        `${GetABatch}?batch_year=${year}&class_name=${className}&division=${division}&page=${page}&count=${entries}`
      );
      setData(response?.data?.all_users);
    } catch (error) {
      console.log(error);
      // toast.error("Something Went Wrong");
      if (error?.response?.data?.msg === "Invalid page.") {
        setData([]);
      }
    } finally {
      setLoading(false);
    }
  };

  // Function to format data as CSV
  const convertToCSV = (objArray) => {
    const array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
    let str = "";

    for (let i = 0; i < array.length; i++) {
      let line = "";
      for (let index in array[i]) {
        if (line !== "") line += ",";

        line += array[i][index];
      }

      str += line + "\r\n";
    }

    return str;
  };

  // Function to download data as CSV
  const downloadData = async () => {
    try {
      const response = await axiosInstance.get(
        `${GetABatch}?batch_year=${year}&class_name=${className}&division=${division}&count=${200}`
      );
      console.log(response?.data)
      const csvData = convertToCSV(response?.data?.all_users);

      const blob = new Blob([csvData], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "student_data.csv";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (error) {
      console.log(error);
      toast.error("Downloading have some error please try again later");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    selectedBatch();
  }, [page]);

  return (
    <div className="md:px-10">
      <div className="flex items-center w-full justify-between">
        <h1 className="font-semibold text-2xl font-mono">Student Details</h1>
        <div className=" flex gap-2">
          <span className="bg-[#F4881F] text-white p-2 px-6 rounded font-semibold">
            {className} - {division}
          </span>
          <button
            onClick={downloadData}
            className="border  bg-[#F4881F] text-white p-2 px-6 rounded font-semibold"
          >
            Download Excel
          </button>
        </div>
      </div>

      <div className="mt-10 min-h-screen">
        <Table data={data} batchData={selectedBatch} page={page} />
        <div className="flex justify-end mt-5">
          <Pagination
            Data={data}
            page={page}
            setPage={setPage}
            getMethod={selectedBatch}
            entries={entries}
          />
        </div>
      </div>
      {loading && <StudentLoader />}
    </div>
  );
};

export default BatchDetailedView;
