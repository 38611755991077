import React, { useEffect, useState } from "react";
import { DailyActivities, GetAllBatches } from "../../utils/EndPoints";
import { toast } from "react-toastify";
import { format, formatDate } from "date-fns";
import axiosInstance from "../../utils/Axios";
import StudentLoader from "../../components/loading/StudentLoader";

const DailyClassUpdate = () => {
  const [classData, setClassData] = useState([]);
  const [yearData, setYearData] = useState([]);
  const [divisionData, setDivisionData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({
    batch_year: "",
    class_name: "",
    division: "",
    media_file: null,
    date: "",
  });

  const handleDateChange = (e) => {
    const selectedDate = e.target.value;
    const formattedDate = format(new Date(selectedDate), "dd/MM/yyyy");
    setForm({
      ...form,
      date: formattedDate,
    });
  };

  const inputChangeHandler = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("media_file", form.media_file);

      const response = await axiosInstance.post(
        `${DailyActivities}?batch_year=${form?.batch_year}&class_name=${form?.class_name}&division=${form?.division}&date=${form?.date}`,
        formData
      );

      if (response?.status === 200) {
        toast.success(response?.data?.message || "Successfully Created");
      }
    } catch (error) {
      console.log(error);
      toast.error("Something Went Wrong");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    axiosInstance
      .get(GetAllBatches)
      .then((res) => {
        const uniqueClassNames = new Set(
          res?.data?.class_details.map((item) => item.class_name)
        );
        const uniqueYears = new Set(
          res?.data?.class_details.map((item) => item.batch_year)
        );
        const uniqueDivisions = new Set(
          res?.data?.class_details.map((item) => item.division)
        );

        // Convert Sets back to arrays
        const uniqueClassData = [...uniqueClassNames].map((className) => ({
          class_name: className,
        }));
        const uniqueYearData = [...uniqueYears].map((year) => ({
          batch_year: year,
        }));
        const uniqueDivisionData = [...uniqueDivisions].map((division) => ({
          division,
        }));

        setClassData(uniqueClassData);
        setYearData(uniqueYearData);
        setDivisionData(uniqueDivisionData);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div className="md:px-10 min-h-[480px]">
      <div className="flex items-center w-full justify-center ">
        <h1 className="font-semibold text-2xl font-mono">
          Daily Class Updates
        </h1>
      </div>
      <form
        onSubmit={submitHandler}
        action=""
        className="border border-[#F4881F]/30 shadow-lg rounded-xl mt-10 p-10 flex flex-col gap-5"
      >
        <div className="flex gap-5 items-center justify-center">
          <div className="border rounded-lg w-full shadow-xl p-10 flex flex-col items-start">
            <label htmlFor="" className="mb-2 font-medium">
              Select Class
            </label>
            <select
              name="class_name"
              onChange={inputChangeHandler}
              id=""
              className="rounded text-sm w-full border border-[#F4881F]/30 p-2 focus:outline-none"
            >
              <option value="">Choose A Class</option>
              {classData.map((items, i) => (
                <option key={i} value={items?.class_name}>
                  {items?.class_name}
                </option>
              ))}
            </select>
          </div>

          <div className="border rounded-lg w-full shadow-xl p-10 flex flex-col items-start">
            <label htmlFor="" className="mb-2 font-medium">
              Select Division
            </label>
            <select
              name="division"
              onChange={inputChangeHandler}
              id=""
              className="rounded text-sm w-full border border-[#F4881F]/30 p-2 focus:outline-none"
            >
              <option value="">Choose A Division</option>
              {divisionData.map((items, i) => (
                <option key={i} value={items?.division}>
                  {items?.division}
                </option>
              ))}
            </select>
          </div>

          <div className="border rounded-lg w-full shadow-xl p-10 flex flex-col items-start">
            <label htmlFor="" className="mb-2 font-medium">
              Select A Year
            </label>
            <select
              name="batch_year"
              onChange={inputChangeHandler}
              id=""
              className="rounded text-sm w-full border border-[#F4881F]/30 p-2 focus:outline-none"
            >
              <option value="">Choose A Year</option>
              {yearData.map((items, i) => (
                <option key={i} value={items?.batch_year}>
                  {items?.batch_year}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="flex gap-5 items-center justify-center">
          <div className="border rounded-lg w-full shadow-xl p-10 flex flex-col items-start">
            <label htmlFor="" className="mb-2 font-medium">
              Select Date
            </label>
            <input
              type="date"
              name="date"
              onChange={handleDateChange}
              // value={form?.date}
              id=""
              className="rounded text-sm w-full border border-[#F4881F]/30 p-2 focus:outline-none"
            />
          </div>

          <div className="border rounded-lg w-full shadow-xl p-10 flex flex-col items-start">
            <label htmlFor="" className="mb-2 font-medium">
              Enter Sheet
            </label>
            <input
              type="file"
              name="media_file"
              onChange={(e) =>
                setForm({ ...form, media_file: e.target.files[0] })
              }
              className="rounded text-sm w-full border border-[#F4881F]/30 p-2 focus:outline-none"
            />
          </div>
        </div>
        <div>
          <button
            type="submit"
            className="bg-[#F4881F] p-2 font-semibold mt-5 text-sm px-5 rounded text-white hover:scale-105 ease-in-out duration-300"
          >
            Upload
          </button>
        </div>
      </form>
      {loading && <StudentLoader />}
    </div>
  );
};

export default DailyClassUpdate;
