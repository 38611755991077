import React, { useEffect, useState } from "react";
import Cards from "../../components/dashboard/Cards";
import { DashboardCard } from "../../data/Dashboard";

const Dashboard = () => {

  
  return (
    <div>
      <div className="hidden md:flex w-full items-center justify-center -mt-10">
        <img src={require("../../assets/logo/sap.png")} alt="sap_logo" className="object-cover" />
      </div>

      <div className="flex flex-wrap items-center justify-start gap-5 md:p-5">
        {DashboardCard.map((items) => (
          <Cards key={items?._id} data={items} />
        ))}
      </div>
    </div>
  );
};

export default Dashboard;
