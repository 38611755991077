import React, { useEffect, useState } from "react";
import { format } from "date-fns";
import DailyClassTable from "../../components/batch/DailyClassTable";
import { useParams } from "react-router-dom";
import axiosInstance from "../../utils/Axios";
import { DailyUpdateStudentData } from "../../utils/EndPoints";
import StudentLoader from "../../components/loading/StudentLoader";

const StudentDailyClass = () => {
  const [allData, setAllData] = useState([]);
  const [monthYear, setMonthYear] = useState();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    axiosInstance
      .get(
        `${DailyUpdateStudentData}?user_id=${id}&date=${monthYear}`
      )
      .then((res) => {
        const results = res?.data?.daily_updates;
        setAllData(results);
        console.log(results);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id, monthYear]);

  console.log(monthYear)

  const handleDateChange = (e) => {
    const selectedDate = e.target.value;
    const formattedDate = format(new Date(selectedDate), "MM/yyyy");
    setMonthYear(formattedDate);
  };

  return (
    <div className="md:px-10 md:min-h-[480px]">
      <div className="flex items-center justify-between">
        <h1 className="text-2xl font-bold font-mono">
          Student Daily Class Information
        </h1>
        <div className="flex flex-col items-start ">
          <label htmlFor="" className="text-sm mb-2 font-medium">
            Select A Month and Year
          </label>
          <input
            type="month"
            name="date"
            onChange={handleDateChange}
            className="text-sm border p-2 rounded text-white bg-[#F4881F]/80 focus:outline-none cursor-pointer"
          />
        </div>
      </div>

      <div className="mt-5">
        <DailyClassTable data={allData} />
      </div>
      {loading && <StudentLoader />}
    </div>
  );
};

export default StudentDailyClass;
