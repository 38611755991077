import axios from "axios";
import { BaseURL } from "./EndPoints";

const axiosInstance = axios.create({
    baseURL: BaseURL,
    // withCredentials: true
})


axiosInstance.interceptors.request.use(
    async (config) => {
        // Get access token from cookies
        const token = document.cookie
            .split("; ")
            .find((row) => row.startsWith("access_token="))
            ?.split("=")[1];

        // Add access token to headers
        if (token) {
            config.headers["Authorization"] = `Bearer ${token}`;
        }

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default axiosInstance;