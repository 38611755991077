import attendance from '../assets/cards/attendance.png'
import markList from '../assets/cards/marklist.png'
import dailyClassUpdate from '../assets/cards/dailyclass.png'

export const BatchReport = [
    {
        _id: 1,
        name: "Attendance",
        path: "/dashboard/batch/student-attendance",
        image: attendance
    },

    {
        _id: 2,
        name: "Mark LIst",
        path: "/dashboard/batch/student-mark_list",
        image: markList
    },
    {
        _id: 3,
        name: "Daily Class Details",
        path: "/dashboard/batch/student-daily_class_info",
        image: dailyClassUpdate
    },
]