import React, { useEffect, useRef, useState } from "react";
import Attandancetable from "../../components/attandance/Attandancetable";
import { FaPlus } from "react-icons/fa6";
import { MdDeleteOutline } from "react-icons/md";

import { Link } from "react-router-dom";
import axiosInstance from "../../utils/Axios";
import {
  DeleteBulkAttendance,
  GetAttendance,
  classNa,
  dateSelect,
  division,
  year,
} from "../../utils/EndPoints";
import { format } from "date-fns";
import { toast } from "react-toastify";
import Pagination from "../../components/Pagination";
import StudentLoader from "../../components/loading/StudentLoader";

const ViewAttendance = () => {
  const [data, setData] = useState({});
  const [classData, setClassData] = useState([]);
  const [yearData, setYearData] = useState([]);
  const [divisionData, setDivisionData] = useState([]);
  const [page, setPage] = useState(1);
  const [entries, setEntries] = useState(100);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    batch_year: "",
    class_name: "",
    division: "",
    subject: "",
    date: "",
  });

  const getAllData = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(
        `${GetAttendance}?page=${page}&count=${entries}`
      );
      setData(response?.data);
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.detail === "Invalid page.") {
        setData();
      }
    } finally {
      setLoading(false);
    }
  };

  console.log(data);

  const inputChangeHandler = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  const handleDateChange = (e) => {
    const selectedDate = e.target.value;
    const formattedDate = format(new Date(selectedDate), "dd/MM/yyyy");
    setFormData({
      ...formData,
      date: formattedDate,
    });
  };
  const submitHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axiosInstance.get(
        `${GetAttendance}?batch_year=${formData.batch_year}&class_name=${formData.class_name}&division=${formData.division}&date=${formData?.date}&subject=${formData?.subject}&page=${page}&count=${entries}`
      );
      console.log(response);
      setData(response?.data);
    } catch (error) {
      console.log(error);
      toast.error("Something Went Wrong");
    } finally {
      setLoading(false);
    }
  };

  const DeleteHandler = async () => {
    const conformClick = window.confirm("Do you want to delete this");
    if (conformClick) {
      setLoading(true);
      try {
        const response = await axiosInstance.delete(
          `${DeleteBulkAttendance}?batch_year=${data?.batch_year}&class_name=${data?.class_name}&division=${data?.division}&date=${data?.current_date}`
        );
        getAllData();
        toast.success(response?.data?.msg || "Successfully Deleted");
      } catch (error) {
        console.log(error);
        toast.error(error?.response?.data?.msg || "Please Try After Sometime");
      } finally {
        setLoading(false);
      }
    }
  };

  const updateData = async (e) => {
    setLoading(true);
    console.log(page)
    try {
      const response = await axiosInstance.get(
        `${GetAttendance}?batch_year=${formData.batch_year}&class_name=${formData.class_name}&division=${formData.division}&date=${formData?.date}&subject=${formData?.subject}&page=${page}&count=${entries}`
      );
      setData(response?.data);
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.msg === "Invalid page.") {
        setData();
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllData();
    window.scrollTo(0, 0);
    axiosInstance
      .get(year)
      .then((res) => {
        setYearData(res?.data?.batch_year);
      })
      .catch((error) => {
        console.log(error);
      });
    axiosInstance
      .get(classNa)
      .then((res) => {
        setClassData(res?.data?.class_name);
      })
      .catch((error) => {
        console.log(error);
      });
    axiosInstance
      .get(division)
      .then((res) => {
        setDivisionData(res?.data?.division);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div>
      {/* add attendance button */}
      <div className="flex flex-col md:flex-row items-center md:justify-between gap-3">
        <h1 className="font-mono font-bold text-2xl text-[#F4881F]">
          Attendance{" "}
        </h1>
        <div className="">
          <h1 className="font-semibold text-lg font-mono text-[#F4881F] uppercase">
            {data?.class_name} - <span>{data?.division}</span> -{" "}
            <span>{data?.batch_year}</span> - <span>{data?.current_date}</span>
          </h1>
        </div>
        <div className="flex items-center gap-3">
          <button
            onClick={DeleteHandler}
            className="bg-red-500 p-2 flex items-center justify-center gap-2 rounded text-white shadow-lg hover:scale-105 ease-in-out duration-300"
          >
            <MdDeleteOutline size={20} />
            Delete
          </button>
          <Link
            to={"/dashboard/add-attendance"}
            className="bg-[#F4881F] p-2 rounded text-white shadow-lg hover:scale-105 ease-in-out duration-300"
          >
            <div className="flex items-center gap-2">
              <FaPlus />
              Add Attendance
            </div>
          </Link>
        </div>
      </div>

      {/* Filter Part */}
      <div className="flex flex-col gap-5 mt-8">
        <form
          onSubmit={submitHandler}
          action=""
          className="w-full gap-5 flex flex-col md:flex-row"
        >
          <div className="w-full flex flex-col items-start">
            <label htmlFor="" className="mb-2 font-medium">
              Select Class
            </label>
            <select
              name="class_name"
              onChange={inputChangeHandler}
              id=""
              className="rounded text-sm w-full border border-[#F4881F]/30 p-2 focus:outline-none"
            >
              <option value="">Choose A Class</option>
              {classData.map((items, i) => (
                <option key={i} value={items}>
                  {items}
                </option>
              ))}
            </select>
          </div>

          <div className=" w-full flex flex-col items-start">
            <label htmlFor="" className="mb-2 font-medium">
              Select Division
            </label>
            <select
              name="division"
              onChange={inputChangeHandler}
              id=""
              className="rounded text-sm w-full border border-[#F4881F]/30 p-2 focus:outline-none"
            >
              <option value="">Choose A Division</option>
              {divisionData.map((items, i) => (
                <option key={i} value={items}>
                  {items}
                </option>
              ))}
            </select>
          </div>

          <div className="w-full flex flex-col items-start">
            <label htmlFor="" className="mb-2 font-medium">
              Select A Year
            </label>
            <select
              name="batch_year"
              onChange={inputChangeHandler}
              id=""
              className="rounded text-sm w-full border border-[#F4881F]/30 p-2 focus:outline-none"
            >
              <option value="">Choose A Year</option>
              {yearData.map((items, i) => (
                <option key={i} value={items}>
                  {items}
                </option>
              ))}
            </select>
          </div>

          <div className="w-full flex flex-col items-start">
            <label htmlFor="" className="mb-2 font-medium">
              Select A Subject
            </label>
            <select
              name="subject"
              onChange={inputChangeHandler}
              id=""
              required
              className="rounded text-sm w-full border border-[#F4881F]/30 p-2 focus:outline-none"
            >
              <option value="">Choose A Subject</option>
              <option value="physics">Physics</option>
              <option value="chemistry">Chemistry</option>
              <option value="maths">Maths</option>
            </select>
          </div>

          <div className="w-full  flex flex-col items-start">
            <label htmlFor="" className="mb-2 font-medium">
              Select Date
            </label>
            <input
              name="date"
              type="date"
              onChange={handleDateChange}
              id=""
              required
              className="rounded text-sm w-full border border-[#F4881F]/30 p-2 focus:outline-none"
            />
          </div>

          <div className="flex justify-end">
            <button
              type="submit"
              className="bg-[#F4881F] p-2 mt-7 font-semibold text-sm px-5 rounded text-white hover:scale-105 ease-in-out duration-300"
            >
              Filter
            </button>
          </div>
        </form>
      </div>

      {/* Table */}
      <div className="mt-5 min:h-screen ">
        <Attandancetable
          data={data?.attendance_result}
          page={page}
          entries={entries}
        />
        <div className="flex justify-end mt-5">
          <Pagination
            Data={data?.attendance_result}
            page={page}
            setPage={setPage}
            getMethod={updateData}
            entries={entries}
          />
        </div>
      </div>
      {loading && <StudentLoader />}
    </div>
  );
};

export default ViewAttendance;
