import React, { useEffect, useState } from "react";
import axiosInstance from "../../utils/Axios";
import { GetAllBatches, RecordedVideo, classNa, division, year } from "../../utils/EndPoints";
import { toast } from "react-toastify";
import StudentLoader from "../../components/loading/StudentLoader";

const VideoClass = () => {
  const [classData, setClassData] = useState([]);
  const [yearData, setYearData] = useState([]);
  const [divisionData, setDivisionData] = useState([]);
  const [view, setView] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({
    class_name: "",
    batch_year: "",
    division: "",
    subject: "",
    date: "",
    recording_link: "",
  });

  const inputChangeHandler = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axiosInstance.post(RecordedVideo, form);
      toast.success(response?.data?.message || "Successfully Created");
      setForm({
        class_name: "",
        batch_year: "",
        division: "",
        subject: "",
        date: "",
        recording_link: "",
      });
      setView(false);
    } catch (error) {
      console.log(error);
      toast.error("Something Went Wrong");
    }finally{
      setLoading(false);
    };
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    axiosInstance
    .get(year)
    .then((res) => {
      setYearData(res?.data?.batch_year);
    })
    .catch((error) => {
      console.log(error);
    });
  axiosInstance
    .get(classNa)
    .then((res) => {
      setClassData(res?.data?.class_name);
    })
    .catch((error) => {
      console.log(error);
    });
  axiosInstance
    .get(division)
    .then((res) => {
      setDivisionData(res?.data?.division);
    })
    .catch((error) => {
      console.log(error);
    });
  }, []);

  return (
    <div className="md:px-10 min-h-[480px]">
      <div className="flex items-center w-full justify-center ">
        <h1 className="font-semibold text-2xl font-mono">
          Daily Video Updates
        </h1>
      </div>

      <div className="border border-[#F4881F]/30 shadow-lg rounded-xl mt-10 p-10 flex flex-col gap-5">
        <form onSubmit={submitHandler} action="" className="">
          <div className="flex flex-col gap-5 items-center justify-center">
            <div className="flex gap-5 items-center justify-center w-full">
              <div className="border rounded-lg w-full shadow-xl p-10 flex flex-col items-start">
                <label htmlFor="" className="mb-2 font-medium">
                  Select Class
                </label>
                <select
                  name="class_name"
                  onChange={inputChangeHandler}
                  value={form?.class_name}
                  required
                  id=""
                  className="rounded text-sm w-full border border-[#F4881F]/30 p-2 focus:outline-none"
                >
                  <option value="">Choose A Class</option>
                  {classData.map((items, i) => (
                    <option key={i} value={items}>
                      {items}
                    </option>
                  ))}
                </select>
              </div>

              <div className="border rounded-lg w-full shadow-xl p-10 flex flex-col items-start">
                <label htmlFor="" className="mb-2 font-medium">
                  Select Division
                </label>
                <select
                  name="division"
                  onChange={inputChangeHandler}
                  value={form?.division}
                  required
                  id=""
                  className="rounded text-sm w-full border border-[#F4881F]/30 p-2 focus:outline-none"
                >
                  <option value="">Choose A Division</option>
                  {divisionData.map((items, i) => (
                    <option key={i} value={items}>
                      {items}
                    </option>
                  ))}
                </select>
              </div>

              <div className="border rounded-lg w-full shadow-xl p-10 flex flex-col items-start">
                <label htmlFor="" className="mb-2 font-medium">
                  Select A Year
                </label>
                <select
                  name="batch_year"
                  onChange={inputChangeHandler}
                  value={form?.batch_year}
                  required
                  id=""
                  className="rounded text-sm w-full border border-[#F4881F]/30 p-2 focus:outline-none"
                >
                  <option value="">Choose A Year</option>
                  {yearData.map((items, i) => (
                    <option key={i} value={items}>
                      {items}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="mb-5 flex items-end justify-end">
            <button
              type="button"
              onClick={() => setView(!view)}
              className="bg-[#F4881F] p-2 font-semibold mt-5 text-sm px-10 rounded text-white hover:scale-105 ease-in-out duration-300"
            >
              Submit
            </button>
          </div>

          {view && (
            <>
              <div className="flex gap-5 items-center justify-center w-full">
                <div className="border rounded-lg w-full shadow-xl p-10 flex flex-col items-start">
                  <label htmlFor="" className="mb-2 font-medium">
                    Select Subject
                  </label>
                  <select
                    name="subject"
                    onChange={inputChangeHandler}
                    value={form?.subject}
                    required
                    id=""
                    className="rounded text-sm w-full border border-[#F4881F]/30 p-2 focus:outline-none"
                  >
                    <option value="">Choose A Class</option>
                    <option value="physics">Physics</option>
                    <option value="chemistry">Chemistry</option>
                    <option value="maths">Maths</option>
                  </select>
                </div>

                <div className="border rounded-lg w-full shadow-xl p-10 flex flex-col items-start">
                  <label htmlFor="" className="mb-2 font-medium">
                    Select Date
                  </label>
                  <input
                    type="date"
                    name="date"
                    onChange={inputChangeHandler}
                    value={form?.date}
                    required
                    id=""
                    className="rounded text-sm w-full border border-[#F4881F]/30 p-2 focus:outline-none"
                  />
                </div>

                <div className="border rounded-lg w-full shadow-xl p-10 flex flex-col items-start">
                  <label htmlFor="" className="mb-2 font-medium">
                    Enter the link
                  </label>
                  <input
                    type="text"
                    name="recording_link"
                    onChange={inputChangeHandler}
                    value={form?.recording_link}
                    required
                    id=""
                    className="rounded text-sm w-full border border-[#F4881F]/30 p-2 focus:outline-none"
                  />
                </div>
              </div>

              <div className="mb-5 flex items-end justify-end">
                <button
                  type="submit"
                  className="bg-[#F4881F] p-2 font-semibold mt-5 text-sm px-10 rounded text-white hover:scale-105 ease-in-out duration-300"
                >
                  Upload
                </button>
              </div>
            </>
          )}
        </form>
      </div>

      {loading && <StudentLoader />}
    </div>
  );
};

export default VideoClass;
