import axios from "../utils/Axios";
import React, { useEffect, useState } from "react";
import { login, resetPassword } from "../utils/EndPoints";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setUser } from "../redux/slices/AuthSlicer";

const Login = () => {
  const [error, setError] = useState({});
  const [form, setForm] = useState({
    email: "",
    password: "",
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userData = useSelector((state) => state.auth);

  useEffect(() => {
    if (userData?.userInfo?.role === "Admin") {
      navigate("/dashboard");
    } else {
      navigate("/login");
    }
  }, [userData, navigate]);

  // Input value reading
  const inputChangeHandler = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  // Axios Call
  const submitHandler = async (e) => {
    console.log(form);
    e.preventDefault();
    try {
      // @dsc Validation
      const validationError = {};

      if (!form.email.trim()) {
        validationError.email = "email is require";
      } else if (!/\S+@\S+\.\S+/.test(form.email)) {
        validationError.email = "email is not valid";
      }

      if (!form.password.trim()) {
        validationError.password = "password is require";
      } else if (form.password.length < 6 || form.password.length > 20) {
        validationError.password =
          "password is minimum containing 6 and maximum containing 20 characters";
      }
      setError(validationError);

      if (Object.keys(validationError).length === 0) {
        const response = await axios.post(login, form);
        console.log(response);

        if (response?.status === 200) {
          const { access_token, refresh_token } = response.data;
          document.cookie = `access_token=${access_token}`;
          document.cookie = `refresh_token=${refresh_token}`;

          // Manual dat for redux
          const user = {
            name: "E-Cube",
            role: "Admin",
          };
          dispatch(setUser(user));
          toast.success("Successfully Login");
        }
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data);
    }
  };

  const resetPasswordHandler = async () => {
    const data = {
      email: form?.email,
    };

    console.log(data);
    try {
      const response = await axios.post(resetPassword, data);
      console.log(response);
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message);
    }
  };

  return (
    <div className="flex flex-col md:flex-row w-full h-full items-center justify-center -mt-20">
      {/* Left Side Data */}
      <div className="w-full flex flex-col items-center justify-center">
        <img
          src={require("../assets/logo/logo.png")}
          alt="logo"
          className="object-cover w-[150px]"
        />
        <img
          src={require("../assets/group.png")}
          alt="Side_Pic"
          className="object-cover w-[400px]"
        />
      </div>

      {/* Right Side Data */}
      <div className="w-full flex flex-col items-center justify-center">
        <img src={require("../assets/logo/sap.png")} alt="Sap_Logo" />
        <div className="bg-[#EFEFEF] py-10 px-10 rounded-xl w-full md:w-3/4 shadow-2xl">
          <form
            action=""
            onSubmit={submitHandler}
            className="flex flex-col items-center w-full md:px-10"
          >
            <h1 className="pb-8 text-[#F4881F] text-xl font-bold">Login</h1>
            <div className="flex flex-col items-center justify-center gap-3 w-full">
              <input
                onChange={inputChangeHandler}
                type="email"
                placeholder="Email"
                name="email"
                className="p-2 bg-white  rounded px-3 focus:outline-none w-full"
              />
              {error.email && (
                <span className="text-[12px] py-2 text-red-600">
                  {error.email}
                </span>
              )}
              <input
                onChange={inputChangeHandler}
                type="password"
                placeholder="Password"
                name="password"
                className="p-2 bg-white  rounded px-3 focus:outline-none w-full"
              />
              {error.password && (
                <span className=" text-[12px] py-2 text-red-600">
                  {error.password}
                </span>
              )}
            </div>
            <div className="mt-3 flex justify-start w-full">
              <h1
                onClick={resetPasswordHandler}
                className="cursor-pointer hover:underline hover:text-blue-500"
              >
                Forgot Password ?
              </h1>
            </div>
            <div className="mt-5">
              <button className="bg-[#F4881F] p-2 px-6 rounded test-white text-white text-sm hover:scale-105 ease-in-out duration-300">
                Login
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
