import React, { useEffect, useRef } from "react";
import emailjs from "@emailjs/browser";
import { toast } from "react-toastify";

const HelpCenter = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        form.current,
        {
          publicKey: process.env.REACT_APP_EMAILJS_USER_ID,
        }
      )
      .then(
        () => {
          console.log("SUCCESS!");
          toast.success("SUCCESS!");
          form.current.reset();
        },
        (error) => {
          console.log("FAILED...", error.text);
          toast.error("FAILED...", error.text);
        }
      );
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  },[]);

  return (
    <div className="flex flex-col md:flex-row">
      <div className="w-full p-5">
        <h1 className="flex text-start font-bold text-3xl font-mono text-[#F4881F]">
          Lets Talk !
        </h1>
        <div className="flex flex-col items-start mt-5">
          <p className="font-bold">We are here to help you.</p>
          {/* <p className="mt-2 text-start">
            <span className="font-semibold">Email</span> :
            qmarktechnolabs@gmail.com
          </p>
          <p className="text-start">
            <span className="font-semibold">Phone</span> : 75608 31210
          </p> */}
        </div>

        <div className="w-full">
          <form ref={form} onSubmit={sendEmail} action="">
            <input
              required
              type="text"
              name="to_name"
              className="border w-full text-sm p-2 rounded mt-5 focus:outline-none"
              placeholder="Enter your Name"
            />
            <input
              required
              type="email"
              name="from_name"
              className="border w-full text-sm p-2 rounded mt-2 focus:outline-none"
              placeholder="Enter your e-mail"
            />

            <textarea
              id=""
              cols="30"
              rows="10"
              name="subject"
              required
              className="border w-full mt-3 rounded-lg p-5 focus:outline-none"
              placeholder="Please Enter Your Message here"
            ></textarea>

            <div className="mt-6 w-full flex">
              <button className="w-full md:w-1/2  text-center bg-[#F4881F] text-white p-2 rounded hover:scale-105 ease-in-out duration-200">
                Send
              </button>
            </div>
          </form>
        </div>
      </div>
      <div className="w-full ">
        <img
          src={require("../../assets/help.png")}
          alt="help"
          className="object cover"
        />
      </div>
    </div>
  );
};

export default HelpCenter;
