import React, { useEffect, useState } from "react";
import { LuCopyPlus } from "react-icons/lu";
import BatchCard from "../../components/batch/BatchCard";
import { toast } from "react-toastify";
import axiosInstance from "../../utils/Axios";
import { AddABatch, GetAllBatches } from "../../utils/EndPoints";
import CreateBatchModal from "../../components/batch/modal/CreateBatchModal";
import StudentLoader from "../../components/loading/StudentLoader";

const Batch = () => {
  const [data, setData] = useState([]);
  const [batchModal, setBatchModal] = useState(false);
  const [submitModal, setSubmitModal] = useState(null);
  const [loading, setLoading] = useState(false);

  const batchData = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(GetAllBatches);
      setData(response?.data?.class_details);
    } catch (error) {
      console.log(error);
      toast.error("Something Went Wrong");
    } finally {
      setLoading(false);
    }
  };

  const createBatch = async () => {
    if (submitModal != null) {
      setLoading(true);
      try {
        const response = await axiosInstance.post(AddABatch, submitModal);
        console.log(response);
        if (response?.status === 201) {
          toast.success(response?.data?.message);
          setBatchModal(false);
          setSubmitModal(null);
          batchData();
        }
      } catch (error) {
        console.log(error);
        toast.error(error?.response?.data?.Message || "Something Went Wrong");
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    batchData();
    createBatch();
  }, [submitModal]);

  return (
    <>
      <div className="w-full min-h-screen md:px-10">
        <div className="flex items-center justify-between">
          <h1 className="font-semibold text-2xl md:text-2xl font-mono">
            Batch
          </h1>
          <button
            onClick={() => setBatchModal(true)}
            className="bg-[#F4881F] text-white text-sm font-semibold p-2  px-8  flex items-center justify-center gap-2 rounded hover:scale-105 ease-in-out duration-300"
          >
            <LuCopyPlus size={20} />
            Create A Batch
          </button>
        </div>

        <div className="flex flex-wrap items-start justify-start mt-10 gap-10">
          {data.map((items) => (
            <BatchCard key={items?.id} data={items} batchData={batchData} />
          ))}
        </div>
      </div>
      {batchModal && (
        <CreateBatchModal
          data={data}
          setModal={setBatchModal}
          submitHandler={setSubmitModal}
        />
      )}
      {loading && <StudentLoader />}
    </>
  );
};

export default Batch;
