import batch from '../assets/cards/batch.png'
import dailyClass from '../assets/cards/dailyclass.png'
import examResult from '../assets/cards/examresults.png'
import attendance from '../assets/cards/attendance.png'
import dailyClassUpdate from '../assets/cards/dailyclass.png'
import videoClass from '../assets/cards/chatbot.png'
import announcements from '../assets/cards/graph.png'
import chatBox from '../assets/cards/chatbot.png'
import addStudent from '../assets/cards/addstudent.png'
import leaderBoard from '../assets/cards/leadrboard.png'
import helpCenter from '../assets/cards/helpcenter.png'

export const DashboardCard = [
    {
        _id: 1,
        name: "Batch",
        path: "/dashboard/batch",
        image: batch
    },

    {
        _id: 2,
        name: "Add Student",
        path: "/dashboard/add-student",
        image: addStudent
    },
    {
        _id: 3,
        name: "Daily Class Update",
        path: "/dashboard/daily-class/view",
        image: dailyClass
    },
    {
        _id: 4,
        name: "Exam Result",
        path: "/dashboard/exam-result",
        image: examResult
    },
    {
        _id: 5,
        name: "Attendance",
        path: "/dashboard/attendance",
        image: attendance
    },
    {
        _id: 6,
        name: "Class Links",
        path: "/dashboard/view-daily-class",
        image: dailyClassUpdate
    },
    {
        _id: 7,
        name: "Video Class",
        path: "/dashboard/view-video-class",
        image: videoClass
    },
    {
        _id: 8,
        name: "Announcements",
        path: "/dashboard/announcements",
        image: announcements
    },
    // {
    //     _id: 9,
    //     name: "Chat Box",
    //     path: "/dashboard/chat-box",
    //     image: chatBox
    // },
    {
        _id: 10,
        name: "Leader Board",
        path: "/dashboard/leader-board",
        image: leaderBoard
    },
    {
        _id: 11,
        name: "Help Center",
        path: "/dashboard/help-center",
        image: helpCenter
    },
]